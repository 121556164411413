import React from 'react'
import { Section } from '../../components/Section'
import styled from 'styled-components'
import { CarouselBox } from '../../components/Carousel'
import { mediaQuery } from '../../util/responsiveUtils'
import { autoLineText } from '../../util/utils'
import { BottomNavigation } from '../../components/BottomNavigation'
import { CurrentWish } from './CurrentWish'
import { MakeWish } from './MakeWish'

const Layout = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 80px;
`

const TitleWrapper = styled.div`
  width: 100%;

  ${mediaQuery('tablet')} {
    max-width: 768px;
  }

  & > div {
    padding: 30px 15px;
    color: #000;
    font-family: gangwon;
    font-size: 24.5px;

    ${mediaQuery('tablet')} {
      font-size: 35px;
    }

    font-style: normal;
    font-weight: 400;
    line-height: 106.8%; /* 34.176px */
    letter-spacing: -0.32px;
  }
`

const Title = ({ text }: { text: string }) => {
  return (
    <TitleWrapper>
      <div>{text}</div>
    </TitleWrapper>
  )
}

const Slogan = ({ text }: { text: string }) => {
  const linedText = autoLineText(text)

  const SloganWrapper = styled.div`
    color: #000;
    text-align: center;

    /* chosun/24 */
    font-family: chosun;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 36px */
    letter-spacing: -0.24px;
  `

  return <SloganWrapper>{linedText}</SloganWrapper>
}

export const Home = () => {
  return (
    <Section bgcolor={`#F8F8F8`}>
      <Layout>
        <Title text={`My Wish Become a Reality`} />
        <Slogan text={`액을 막아주고, 소원을 들어주는\n장승패밀리에게 소원을 적어보자!`} />
        <CurrentWish />
        <MakeWish />
        <CarouselBox />
      </Layout>
      <BottomNavigation />
    </Section>
  )
}
