export interface TotemInfo {
  nameKor: string
  nameEng: string
  description: string
  content: string
  img: string
}

export const totemInfos: TotemInfo[] = [
  {
    nameEng: 'paha',
    nameKor: '파하',
    img: `./img/totems/paha.svg`,
    description: `양의 기운`,
    content: `재물 장승`,
  },
  {
    nameEng: 'maha',
    nameKor: '마하',
    img: `./img/totems/maha.svg`,
    description: `음의 기운`,
    content: `건강 & 사랑 장승`,
  },
  {
    nameEng: 'aji',
    nameKor: '아지',
    img: `./img/totems/aji.svg`,
    description: `수(水), 물을 조정하는 초능력`,
    content: `합격, 문서 장승`,
  },
  {
    nameEng: 'pook',
    nameKor: '푸크',
    img: `./img/totems/pook.svg`,
    description: `화(火), 불을 조정하는 초능력`,
    content: `인연 장승\n(연애, 친구 사귀기, 새로운 인연을\n 맺게하는 장승)`,
  },
  {
    nameEng: 'nai',
    nameKor: '나이',
    img: `./img/totems/nai.svg`,
    description: `목(木), 나무, 식물을 조정하는 초능력`,
    content: `안전 장승`,
  },
  {
    nameEng: 'tik',
    nameKor: '티크',
    img: `./img/totems/tik.svg`,
    description: `금(金), 금속을 조정하는 초능력`,
    content: `Happy 장승\n(항상 웃음과 즐거움을 주는 장승)`,
  },
  {
    nameEng: 'tok',
    nameKor: '토크',
    img: `./img/totems/tok.svg`,
    description: `토(土), 흙을 조정하는 초능력`,
    content: `가디언 장승\n(나쁜 것을 막아주는 장승)`,
  },
]
