import styled from 'styled-components'
import { Section } from '../../components/Section'
import { SubjectWithBack } from '../../components/Subject'
import { TitleBackGround } from '../location/location'
import React, { ReactNode } from 'react'
import { useFestivals } from '../../context/FestivalContext'
import { useNavigate, useParams } from 'react-router-dom'
import { mediaQuery } from '../../util/responsiveUtils'
import { BottomNavigation } from '../../components/BottomNavigation'

const Background = styled.div`
  background-color: ${({ theme }) => theme.color.almostWhite};
`

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: pretendard;
  height: 100%;
  gap: 20px;
  min-height: 100vh;
`

interface ButtonProps {
  children?: ReactNode
  route?: string
  value?: string
}

const BackButtonComponent = styled.button`
  border: none;
  background-color: white;
  display: flex;
`

const TopNavigation = ({ title }: { title: string }) => {
  const NewsBackButton = ({ children }: ButtonProps) => {
    const navigate = useNavigate()

    return <BackButtonComponent onClick={() => {
      navigate('/event')
    }}>{children}</BackButtonComponent>
  }

  return (
    <TitleBackGround>
      <SubjectWithBack text={title} fontSize={`18px`}>
        <NewsBackButton>
          <img src={`/img/back_icon.svg`} />
        </NewsBackButton>
      </SubjectWithBack>
    </TitleBackGround>
  )
}

const ContentWrapper = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 768px;
  margin: 0 auto;
  gap: 20px;
`

const FestivalImage = styled.img`
  width: 100%;
  height: 100px;
  display: flex;
  object-fit: cover;
  min-width: 320px;
  max-width: 640px;
  min-height: 200px;
  max-height: 400px;
  border-radius: 4px;

  ${mediaQuery('tablet')} {
    height: 400px;
  }
`

const BodyWrapper = styled.div`
  width: 100%;
  text-align: start;
`

const FestivalBody = ({ body }: { body: string }) => {
  return <BodyWrapper dangerouslySetInnerHTML={{ __html: body }} />
}

export const NewsDetails = () => {
  const festivals = useFestivals()
  const { id } = useParams()
  const festival = festivals && festivals.find(f => f.id === id)!

  return (
    <Section>
      <Background>
        <Layout>
          <TopNavigation title={festival.title} />
          <ContentWrapper>
            <FestivalImage src={festival.image.src} />
            <FestivalBody body={festival.body} />
          </ContentWrapper>
          <BottomNavigation />
        </Layout>
      </Background>
    </Section>
  )
}
