import styled from 'styled-components'
import React, { useState } from 'react'
import { mediaQuery } from '../util/responsiveUtils'
import { useLocations } from '../context/LocationContext'
import { useDonationState } from '../context/DonationContext'

const LocationCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;

  ${mediaQuery('tablet')} {
    max-width: 768px;
  }
`

const LocationCard = styled.button`
  padding: 12px 12px 20px 12px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: ${(props) => (props.value === props.name ? '1px solid black;' : '1px solid #dde0e4;')};
  background-color: white;
  width: 100%;
  gap: 10px;
`

const BandWrapper = styled.div`
  width: calc(100% - 16px);
  display: flex;
  flex-direction: row;
  padding: 8px;
  gap: 12px;
  border-radius: 3px;
  background: #f2f2f2;
  align-items: center;
`

interface BandProps {
  iconSrc: string
  title: string
  content: string | undefined | null
}

const BandTitle = styled.p`
  color: #626262;
  font-family: pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  letter-spacing: -0.28px;
  flex-basis: 10%;
  margin: 0;
  text-align: start;
`

const BandContent = styled.p`
  color: #1b1b1c;
  font-family: pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  letter-spacing: -0.28px;
  flex-basis: auto;
  text-align: start;
  margin: 0;
`

const BandIcon = styled.img`
  width: 16px;
`

const BandIconWrapper = styled.div`
  flex-basis: 5%;
  display: flex;
  align-items: center;
  margin-right: -6px;
`

const Band = ({ iconSrc, title, content }: BandProps) => {
  return (
    <BandWrapper key={content}>
      <BandIconWrapper>
        <BandIcon src={iconSrc} />
      </BandIconWrapper>
      <BandTitle>{title}</BandTitle>
      <BandContent>{content}</BandContent>
    </BandWrapper>
  )
}

const LocationName = styled.div`
  color: var(--text-base, #1b1b1c);
  font-family: chosun;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  letter-spacing: -0.2px;
  margin-bottom: 6px;
`

export const LocationCards = ({ selectable = 'false' }: { selectable?: string }) => {
  const locations = useLocations()
  const donationState = useDonationState()

  const [value, setValue] = useState('')

  const Cards = () => {
    const cards = locations.map((d, index) => {
      const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (selectable === 'true') {
          setValue(e.currentTarget.name)
          donationState.setDonation({
            ...donationState.donation,
            location: {
              name: e.currentTarget.name,
              address: d.address,
            },
          })
        }
      }

      return (
        <LocationCard key={index} onClick={onClick} value={value} name={d.name}>
          <LocationName>{d.name}</LocationName>
          <Band iconSrc={`./img/icon/location_grey.svg`} title={`주소`} content={`${d.address}`} />
          {d.tel && <Band iconSrc={`./img/icon/tel.svg`} title={`전화`} content={`${d.tel}`} />}
        </LocationCard>
      )
    })
    return <>{cards}</>
  }

  return locations[0].name ? (
    <LocationCardWrapper>
      <Cards />
    </LocationCardWrapper>
  ) : (
    <></>
  )
}
