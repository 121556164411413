import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { auth } from '../firebase/config'
import { GetArticleDto, getArticlesByUserUid } from '../firebase/article'
import { Collapse } from 'react-collapse'
import { useLocations } from '../context/LocationContext'
import { timestampToDate } from '../firebase/common'

const ArticleTitle = styled.div`
  color: var(--text-base, #1b1b1c);
  padding-left: 6px;

  /* chosun/16 */
  font-family: chosun;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;
`

const ArticleInfoWrapper = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 3px;
  background: #f2f2f2;
`

const wishTypes = () => {
  return [
    { kor: `파하`, color: '#6C6CA0' },
    { kor: `마하`, color: '#B94850' },
    { kor: `아지`, color: '#1DF300' },
    { kor: `푸크`, color: '#5AF7EF' },
    { kor: `나이`, color: '#CE794A' },
    { kor: `티크`, color: '#FDE028' },
    { kor: `토크`, color: '#FF7605' },
    { kor: `etc`, color: '#FF7605' },
  ]
}

const getColorByTagKor = (kor: string): string => {
  return wishTypes().find((type) => type.kor === kor)?.color || '#FF7605'
}

const ArticleCardLayout = styled.div<{ display: string }>`
  width: calc(100% - 40px);
  background-color: white;
  padding: 20px;
  display: ${(props) => props.display || 'none;'}
  flex-direction: column;
  gap: 12px;
  text-align: start;
  border-radius: 4px;
  border: 1px solid #dee2e6;
  align-items: start;
  justify-content: center;
`

interface TagProps {
  kor: string
  color?: string
}

const TagWrapper = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  display: flex;
  padding: 2px 6px;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 10px;
  border-radius: 4px;
`

const TagText = styled.div`
  color: white;
  text-align: justify;

  /* chosun/14 */
  font-family: chosun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
`

const Tag = ({ kor, color }: TagProps) => {
  return (
    <TagWrapper color={color || `ADB5BD`}>
      <TagText key={kor}>{kor}</TagText>
    </TagWrapper>
  )
}

const ArticleInfoLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: start;
  gap: 12px;
`

const ArticleInfoTitle = styled.div`
  color: #626262;

  /* pretendard/13 */
  font-family: pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.26px;
`

const ArticleInfoContent = styled.div`
  color: #1b1b1c;

  /* pretendard/13 */
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.26px;
`

const CollapseWrapper = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border: none;
  background-color: white;
  outline: none;

  color: var(--gray-70, #495057);

  /* pretendard/14 */
  font-family: pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
`

const ButtonArrow = styled.img<{ isopen: string }>`
  transform: ${(props) => (props.isopen === `true` ? `rotate(180deg)` : ``)};
`

const ContentWrapper = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  gap: 4px;
  border-radius: 3px;
  border: 1px solid var(--gray-20, #e9ecef);
  background: var(--gray-00, #fff);
`

const ContentTitle = styled.div`
  color: var(--gray-60, #868e96);
  margin-bottom: 5px;

  /* pretendard/12 */
  font-family: pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.24px;
`

const ContentText = styled.div`
  color: #1b1b1c;

  /* pretendard/13 */
  font-family: pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.26px;
`

const ArticleInfo = (article: GetArticleDto, locationName: string) => (
  <ArticleInfoWrapper>
    <ArticleInfoLine>
      <ArticleInfoTitle>{`작성일`}</ArticleInfoTitle>
      <ArticleInfoContent>{timestampToDate(article.createdate)}</ArticleInfoContent>
    </ArticleInfoLine>
    <ArticleInfoLine>
      <ArticleInfoTitle>{`소원지 위치`}</ArticleInfoTitle>
      <ArticleInfoContent>{locationName}</ArticleInfoContent>
    </ArticleInfoLine>
  </ArticleInfoWrapper>
)

const CollapseCustomWrapper = styled.div`
  width: 100%;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.grey20};
  max-width: 768px;
`

const Notice = styled.div`
  font-family: chosun;
  font-size: 16px;
  padding: 40px 0;
`

const ArticleCard = ({
  article,
  display,
  locationName,
}: {
  article: GetArticleDto
  display: string
  locationName: string
}) => {
  const [isButtonCollapseOpen, setIsButtonCollapseOpen] = useState(false)

  const onClick = useCallback(() => setIsButtonCollapseOpen(!isButtonCollapseOpen), [isButtonCollapseOpen])

  return display === 'flex;' && article !== undefined ? (
    <ArticleCardLayout display={display}>
      <Tag kor={article.totemName} color={getColorByTagKor(article.totemName)} />
      <ArticleTitle>{article.title}</ArticleTitle>
      {ArticleInfo(article, locationName)}
      <CollapseCustomWrapper>
        <Collapse isOpened={isButtonCollapseOpen}>
          <ContentWrapper className="blob">
            <ContentTitle>{`소원내용`}</ContentTitle>
            <ContentText>{article.content}</ContentText>
          </ContentWrapper>
        </Collapse>
      </CollapseCustomWrapper>
      <Divider />
      <CollapseWrapper aria-expanded={isButtonCollapseOpen} onClick={onClick} type="button">
        {!isButtonCollapseOpen ? `소원내용 확인하기` : `소원내용 숨기기`}
        <ButtonArrow isopen={`${isButtonCollapseOpen}`} src={`./img/icon/arrow_down.svg`} />
      </CollapseWrapper>
    </ArticleCardLayout>
  ) : (
    <Notice>{`소원지 작성 기록이 없습니다`}</Notice>
  )
}

export const ArticleCards = () => {
  const [articles, setArticles] = useState([] as GetArticleDto[])
  const [display, setDisplay] = useState('none;')

  useEffect(() => {
    getArticlesByUserUid(auth.currentUser?.uid || '').then((datas) => {
      setArticles(datas ? datas : [])
      setDisplay(datas ? 'flex;' : 'none;')
    })
  }, [auth.currentUser])

  const locations = useLocations()

  const ArticleCardList = () => {
    const articleCardList = articles.map((articleDto, index) => {
      const pick = locations.find((l) => l.id === articleDto.locationId)
      return (
        <ArticleCard key={articleDto.id} article={articleDto} display={display} locationName={pick ? pick.name : ''} />
      )
    })
    return <>{articleCardList}</>
  }

  return <ArticleCardList />
}
