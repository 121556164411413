const greyScaleColors = {
  black: '#000000',
  grey90: '#212529',
  grey80: '#343A40',
  grey70: '#495057',
  grey60: '#868E96',
  grey50: '#ADB5BD',
  grey40: '#CED4DA',
  grey30: '#DEE2E6',
  grey20: '#E9ECEF',
  grey10: '#F1F3F5',
  white: '#ffffff'
}

const wishTypeColors = {
  love: '#F17E7E',
  money: '#FEB429',
  health: '#0081B8',
  protect: '#9C7EF1',
  pass: '#33AC88',
  drive: '#448FD4',
  etc: '#ADB5BD'
}

const theme = {
  color: {
    ...wishTypeColors,
    ...greyScaleColors,
    primary: '#FAF7F4',
    text1: '#848484',
    logoText: '#495057',
    cream: '#FAF7F4',
    modalText: '#24272B',
    almostWhite: '#F8F8F8',
    divider: '#E9EBEC'
  }
}

export default theme
