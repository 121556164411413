import { collection, getDocs } from 'firebase/firestore'
import { db } from './config'
import { returnUndefined } from './common'

interface GoodsImageDto {
  src: string
  title: string
}

interface GoodsDto {
  title: string
  content: string
  image: GoodsImageDto
}

interface GetGoodsDto extends GoodsDto {
  id: string
}

const getGoods = async (): Promise<GetGoodsDto[] | undefined> => {
  return await getDocs(collection(db, 'goods'))
    .then((snapshot) => {
      return snapshot.docs.map((doc) => {
        const docData = doc.data()
        const goods = {
          id: doc.id,
          ...docData
        } as GetGoodsDto
        return goods
      })
    }).catch(returnUndefined)
}

export type { GoodsDto, GoodsImageDto, GetGoodsDto }
export { getGoods }
