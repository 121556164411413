import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Section } from '../../components/Section'
import styled from 'styled-components'
import { SubjectWithBack } from '../../components/Subject'
import { BackButton } from '../../components/Button'
import { Status, Wrapper } from '@googlemaps/react-wrapper'
import { BottomNavigation } from '../../components/BottomNavigation'
import { useLocations } from '../../context/LocationContext'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: chosun;
  height: 100%;
`

const BackGround = styled.div`
  background-color: ${({ theme }) => theme.color.almostWhite};
  width: 100%;
  height: calc(100vh - 124px);
  padding: 0 0 80px 0;
  max-width: 768px;
`

export const TitleBackGround = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: chosun;
  width: 100%;
`

const LocationCardsWrapper = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: center;
  gap: 18px;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.grey20};
  max-width: 768px;
`

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
}

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>()

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker())
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [marker])

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options)
    }
  }, [marker, options])

  return null
}

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef()

  ref.current = value

  return ref.current
}

function useDeepCompareEffectForMaps(callback: React.EffectCallback, dependencies: any[]) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize))
}

const Map: React.FC<MapProps> = ({ onIdle, children, style, ...options }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<google.maps.Map>()

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}))
    }
  }, [ref, map])

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options)
    }
  }, [map, options])

  React.useEffect(() => {
    if (map) {
      ['idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName))

      if (onIdle) {
        map.addListener('idle', () => onIdle(map))
      }
    }
  }, [map, onIdle])

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map })
        }
      })}
    </>
  )
}

export const Location = () => {
  const key = process.env.REACT_APP_GOOGLE_API_KEY || 'AIzaSyDVQSiduE63O02t33bzEvbilF9PdK083d4'
  const center = { lat: 36.7, lng: 127.83 }
  const zoom = 7.5

  const render = (status: Status): ReactElement => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>
    if (status === Status.FAILURE) return <h3>{status} ...</h3>
    return <></>
  }

  const locations = useLocations()

  return (
    <Section bgcolor={`#F8F8F8`}>
      <Layout>
        <TitleBackGround>
          <SubjectWithBack text={`장소 안내`} fontSize={`18px`}>
            <BackButton>
              <img src={`./img/back_icon.svg`} />
            </BackButton>
          </SubjectWithBack>
        </TitleBackGround>
        <Divider />
        <BackGround>
          <Wrapper apiKey={key} render={render} libraries={['marker']}>
            <Map center={center} zoom={zoom} style={{ flexGrow: '2', height: '100%' }} panControl={false}
                 disableDefaultUI={true} zoomControl={true} fullscreenControl={true}
            >
              {locations &&
                locations.map((l, i) => {
                  const latLng = { lat: l.latitude, lng: l.longitude }
                  const markerLabel = {
                    color: '#000',
                    fontFamily: 'chosun',
                    backgroundColor: '#fff',
                    fontSize: '20px',
                    text: l.name
                  }
                  return <Marker key={i} position={latLng} label={markerLabel} />
                })}
            </Map>
          </Wrapper>
          <BottomNavigation />
        </BackGround>
      </Layout>
    </Section>
  )
}
