import styled from 'styled-components'
import { mediaQuery } from '../util/responsiveUtils'

const LogoArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.p`
  color: ${({ theme }) => theme.color.logoText};
  font-family: pretendard;
  font-size: 20px;

  ${mediaQuery('tablet')} {
    font-size: 30px;
  }
`

const AppTitle = styled.div`
  margin: 60px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TitleImage = styled.img`
  width: 210px;

  ${mediaQuery('tablet')} {
    width: 310px;
  }
`

const CharactersWrapper = styled.div`
  width: 240px;
  display: flex;
  flex-direction: row;
  margin: 40px 0 40px 0;
  justify-content: center;
`

const Image = styled.img`
  width: 100%;
  max-width: 180px;
`

const Characters = () => {
  return (
    <CharactersWrapper>
      <Image src={`/img/welcome_character1.svg`} />
      <Image src={`/img/welcome_character2.svg`} />
    </CharactersWrapper>
  )
}

export const Welcome = () => {
  return (
    <LogoArea>
      <AppTitle>
        <TitleImage src={`/img/app_title.svg`} />
        <Characters />
      </AppTitle>
    </LogoArea>
  )
}
