import React, { createContext, useContext, useEffect, useState } from 'react'
import { GetLocationDto, getLocations } from '../firebase/location'

const LocationsContext = createContext<GetLocationDto[] | undefined>(undefined)

export const LocationsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [locations, setLocations] = useState([{} as GetLocationDto])

  useEffect(() => {
    getLocations().then((r) => {
      Array.isArray(r) && setLocations(r)
    })
  }, [])

  return <LocationsContext.Provider value={locations}>{children}</LocationsContext.Provider>
}

export const useLocations = () => {
  const locations = useContext(LocationsContext)
  if (!locations) throw new Error('LocationsProvider no found')
  return locations
}
