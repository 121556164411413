import styled from 'styled-components'
import { Input } from './Input'
import React, { useState } from 'react'
import { LoginButton, WhiteButton } from './Button'
import { Spacing } from './Spacing'
import { mediaQuery } from '../util/responsiveUtils'
import { signInAuth } from '../firebase/auth'
import { useNavigate } from 'react-router-dom'

const Layout = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;

  ${mediaQuery('tablet')} {
    max-width: 768px;
  }
`
const AuthWrapper = styled.div`
  min-height: 314px;
  padding: 20px;
  border-radius: 16px 16px 0 0;
  background: var(--background-base, #fff);
  box-shadow: 0px -1px 10px 0px rgba(20, 70, 50, 0.08);
`

export const AuthCard = ({ setErrorDisplay }: { setErrorDisplay: React.Dispatch<React.SetStateAction<string>> }) => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const onclick = async () => {
    await signInAuth({ email, password }).then((res) => {
      res.error && setErrorDisplay('flex;')
      res.body && navigate('/', { replace: true })
    })
  }

  return (
    <Layout>
      <AuthWrapper>
        <Input
          label={`아이디`}
          placeHolder={`이메일주소를 입력해주세요`}
          inputType={'email'}
          value={email}
          setState={setEmail}
          required={true}
          invalidText={`이메일 주소를 입력해주세요.`}
        ></Input>
        <Input
          label={`비밀번호`}
          placeHolder={`비밀번호를 입력해주세요`}
          inputType={'password'}
          value={password}
          setState={setPassword}
          required={true}
          invalidText={`비밀번호를 입력해주세요.`}
        ></Input>
        {Spacing({ width: `100%`, height: `10px` })}
        <LoginButton onClick={onclick}>{`로그인`}</LoginButton>
        {Spacing({ width: `100%`, height: `10px` })}
        <WhiteButton route={`/signUp`}>{`회원가입`}</WhiteButton>
      </AuthWrapper>
    </Layout>
  )
}
