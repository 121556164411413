import { db } from './config'
import { collection, getDocs } from 'firebase/firestore'
import { returnUndefined } from './common'

interface FestivalImageDto {
  src: string
  title: string
}

interface FestivalDto {
  date: string
  title: string
  place: string
  time: string
  host: string
  body: string
  image: FestivalImageDto
}

interface GetFestivalDto extends FestivalDto {
  id: string
}

const getFestivals = async (): Promise<GetFestivalDto[] | undefined> => {
  return await getDocs(collection(db, 'festivals'))
    .then((snapshot) => {
      return snapshot.docs.map((doc) => {
        const docData = doc.data()
        const festival = {
          id: doc.id,
          ...docData
        } as GetFestivalDto
        return festival
      })
    })
    .catch(returnUndefined)
}

export type { FestivalImageDto, FestivalDto, GetFestivalDto }
export { getFestivals }
