import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/fonts.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
    />
    <script src={'https://unpkg.com/react/umd/react.production.min.js'}></script>
    <script src={'https://unpkg.com/react-collapse/build/react-collapse.min.js'}></script>
    <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDVQSiduE63O02t33bzEvbilF9PdK083d4&callback=initializeMap"></script>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
