import styled, { css } from 'styled-components'
import React, { useState } from 'react'
import { useArticleState } from '../context/ArticleContext'

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.label`
  padding: 0 0 8px 4px;
  font-family: pretendard;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.grey90};
  align-self: start;
`

const TextInput = styled.input<{ invalid?: string; focus: string }>`
  padding: 16px;
  border-radius: 6px;
  background: white;
  outline: none;
  border: 1px solid #e9ebec;

  font-family: pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;

  &::placeholder {
    color: ${({ theme }) => theme.color.grey50};
  }

  ${(props) =>
    props.focus === 'true' &&
    css`
      &:invalid {
        border: 2px solid var(--Red, #ff6b6b);
      }

      &:valid {
        border: 1px solid #e9ecef;
      }
    `}
`

const InvalidText = styled.div<{ invalid?: string; focus: string }>`
  color: var(--Red, #FF6B6B);
  padding: 4px 4px 0 4px;
  text-align: start;
  display: ${(props) => (props.focus === 'true' && props.invalid === 'true' ? 'block;' : 'none;')}

  /* 12/medium */
  font-family: pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
  letter-spacing: -0.24px;
`

interface InputProps {
  label?: string
  placeHolder?: string
  invalidText?: string
  value: string
  setState?: React.Dispatch<React.SetStateAction<string>>
  required?: boolean
}

export const InputNew = ({ label, placeHolder, invalidText, value, setState, required = false }: InputProps) => {
  const [focus, setFocus] = useState('false')
  const articleState = useArticleState()
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState && setState(e.currentTarget.value)
    articleState.setArticle({ ...articleState.article, title: e.currentTarget.value })
  }

  const onFocus = () => setFocus('true')

  return (
    <InputWrapper>
      {label && <Label>{label}</Label>}
      <TextInput
        required={required}
        value={value}
        placeholder={placeHolder}
        onChange={onChange}
        onFocus={onFocus}
        focus={focus}
      ></TextInput>
      {invalidText && <InvalidText focus={focus}>{invalidText}</InvalidText>}
    </InputWrapper>
  )
}
