import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

interface ButtonProps {
  children?: ReactNode
  route?: string
  value?: string
}

const Button = styled.button`
  width: 100%;
  padding: 16px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-family: chosun;
  font-size: 16px;
`

const BackButtonComponent = styled.button`
  border: none;
  background-color: white;
  display: flex;
`

export const BlackButtonComponent = styled(Button)`
  background: ${({ theme }) => theme.color.grey80};
  border: none;
  color: white;
`

export const WhiteButtonComponent = styled(Button)`
  background: white;
  border: 1px solid ${({ theme }) => theme.color.grey30};
  color: black;
`

export const BlackButton = ({ children, route, value }: ButtonProps) => {
  const navigate = useNavigate()

  return <BlackButtonComponent onClick={() => {
    route ? navigate(route, { state: value }) : {}
  }}>{children}</BlackButtonComponent>
}

export const WhiteButton = ({ children, route, value }: ButtonProps) => {
  const navigate = useNavigate()

  return <WhiteButtonComponent onClick={() => {
    route ? navigate(route, { state: value }) : {}
  }}>{children}</WhiteButtonComponent>
}

export const BackButton = ({ children }: ButtonProps) => {
  const navigate = useNavigate()

  return <BackButtonComponent onClick={() => {
    navigate(-1)
  }}>{children}</BackButtonComponent>
}

export const LoginButton = styled(BlackButtonComponent)``
