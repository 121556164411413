import { collection, getDocs } from 'firebase/firestore'
import { db } from './config'
import { returnUndefined } from './common'

interface AdImageDto {
  src: string
  title: string
}

interface AdDto {
  image: AdImageDto
  link: string
  order: number
}

interface GetAdDto extends AdDto {
  id: string
}

const getAds = async (): Promise<GetAdDto[] | undefined> => {
  return await getDocs(collection(db, 'ads'))
    .then((snapshot) => {
      return snapshot.docs.map((doc) => {
        const docData = doc.data()
        const ad = {
          id: doc.id,
          ...docData
        } as GetAdDto
        return ad
      })
    })
    .catch(returnUndefined)
}

export type { GetAdDto }
export { getAds }
