import { CreateField, getCurrentTimestamp, returnFalse } from './common'
import { addDoc, collection } from 'firebase/firestore'
import { db } from './config'

export interface ReceiveInfo {
  name: string
  phoneNumber: string
  address: string
  zipCode: string
  extraAddress: string
}

export interface LocationInfo {
  name: string
  address: string
}

export interface DonationDto {
  price: string
  goodsName: string
  receive?: ReceiveInfo
  location?: LocationInfo
}

interface PostDonationReq extends DonationDto, CreateField {
  locationId?: string
  userId: string
}

const createDonation = async (userId: string, donation: DonationDto) => {
  const now = getCurrentTimestamp()
  return await addDoc(collection(db, 'donations'), {
    ...donation,
    createdate: now,
    createdby: userId,
    lastupdate: now,
    updatedby: userId,
    userId: userId
  } as PostDonationReq)
    .then((snapshot) => {
      return !!snapshot
    })
    .catch(returnFalse)
}

export type { PostDonationReq }
export { createDonation }
