import React, { SetStateAction, useEffect, useState } from 'react'
import { Section } from '../../components/Section'
import styled from 'styled-components'
import { Subject } from '../../components/Subject'
import { BottomNavigation } from '../../components/BottomNavigation'
import { BlackButtonComponent } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { SelectTotem } from './SelectTotem'
import { WriteArticle } from './WriteArticle'
import { ArticleContextProvider, useArticleState } from '../../context/ArticleContext'
import { auth } from '../../firebase/config'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from '../../context/AuthContext'
import { createArticle, PostArticleDto } from '../../firebase/article'
import { Modal } from '../../components/AuthCodeModal'

const Layout = styled.div`
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  font-family: chosun;
`

const ButtonLayout = styled.div`
  width: 100%;
`
const ButtonWrapper = styled.div`
  padding: 0 20px;
  max-width: 768px;
  margin: 0 auto;
`

const SubmitButton = ({
  setValidate,
  setDisplay,
}: {
  setValidate: React.Dispatch<SetStateAction<boolean>>
  setDisplay: React.Dispatch<SetStateAction<string>>
}) => {
  const articleState = useArticleState()
  const navigate = useNavigate()

  const validateForm = (article: PostArticleDto) => {
    return !Object.values(article).includes('')
  }

  const onClick = async () => {
    const article = { ...articleState.article }
    const validate = validateForm(article)
    setValidate(validate)

    if (validate) {
      await createArticle(auth.currentUser!.uid, article).then((res) => res && navigate('/event', { state: 'event' }))
    } else {
      setDisplay('flex;')
    }
  }

  return (
    <ButtonLayout>
      <ButtonWrapper>
        <BlackButtonComponent onClick={onClick} value={'event'}>{`소원지 달기`}</BlackButtonComponent>
      </ButtonWrapper>
    </ButtonLayout>
  )
}

export const Article = () => {
  const [validate, setValidate] = useState(false)
  const [display, setDisplay] = useState('none;')

  const authState = useAuthState()
  const navigate = useNavigate()
  useEffect(() => {
    if (authState.state === 'loading') {
    } else if (authState.state === 'error') {
      navigate('/login', { replace: true })
    } else if (authState.state === 'loaded') {
      if (!authState.user) navigate('/login', { replace: true })
    }
  }, [auth.currentUser])

  return authState.state === 'loaded' && authState.user ? (
    <ArticleContextProvider>
      <Section bgcolor={`#F8F8F8`}>
        <Layout>
          <Subject text={`소원지 작성하기`}></Subject>
          <SelectTotem />
          <Divider />
          <WriteArticle />
          <SubmitButton setValidate={setValidate} setDisplay={setDisplay} />
        </Layout>
        <BottomNavigation />
        {!validate && (
          <Modal
            title={'소원지 작성 실패'}
            text={'모든 정보를 입력해주세요.'}
            buttonText={'확인'}
            display={display}
            setDisplay={setDisplay}
          />
        )}
      </Section>
    </ArticleContextProvider>
  ) : (
    <></>
  )
}
