import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import styled from 'styled-components'
import { mediaQuery } from '../util/responsiveUtils'
import { getAds } from '../firebase/ad'

const CarouselBoxWrapper = styled.div`
  margin: 20px 0;
  width: 320px;
  height: 200px;
  border-radius: 16px;
  background: lightgray 50% / cover no-repeat;
  overflow: hidden;

  ${mediaQuery('tablet')} {
    width: 412px;
    height: 257.5px;
  }
`

const CarouselWrapper = styled.div`
  border-radius: 16px;
  background: lightgray 50% / cover no-repeat;

  width: 320px;
  height: 200px;

  ${mediaQuery('tablet')} {
    width: 412px;
    height: 257.5px;
  }

  & > img {
    object-fit: cover;
    height: 200px;

    ${mediaQuery('tablet')} {
      height: 257.5px;
    }
  }
`

export const CarouselBox = () => {
  const [renderSlides, SetRenderSlides] = useState([] as JSX.Element[])

  useEffect(() => {
    getAds().then((data) => {
      if (data) {
        const renderSlides = data
          .sort((a, b) => {
            return a.order - b.order
          })
          .map((ad) => {
            const onClick = (e: React.MouseEvent) => {
              window.open(ad.link, '_blank')
            }

            return (
              <CarouselWrapper key={ad.id} onClick={onClick}>
                <img src={ad.image.src} />
              </CarouselWrapper>
            )
          })
        SetRenderSlides(renderSlides)
      }
    })
  }, [])

  return (
    <CarouselBoxWrapper>
      <Carousel showArrows={true} showIndicators={false} showStatus={false} showThumbs={false}>
        {renderSlides && renderSlides}
      </Carousel>
    </CarouselBoxWrapper>
  )
}
