import styled from 'styled-components'
import React from 'react'
import { BlackButtonComponent } from './Button'
import { autoLineText } from '../util/utils'
import { ILocation } from '../location/useGeoLocation'
import { useNavigate } from 'react-router-dom'

const Layout = styled.div<{ display?: string }>`
  position: fixed;
  width: calc(100% - 40px);
  height: 100%;
  justify-items: center;
  padding: 0 20px;
  background-color: rgba(0, 0, 0, 0.2);
  display: ${(props) => (props.display === 'none;' ? 'none;' : 'flex;')} | 'none;';
`

const ModalWrapper = styled.div`
  background-color: white;
  padding: 24px 16px 20px 16px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: auto;
  position: relative;
  gap: 16px;
  width: 100%;
  max-width: 736px;
`

const AgreeGeolocationModalWrapper = styled.div<{ display: string }>`
  width: 100%;
  position: absolute;
  top: 0;
  margin: 0 auto;
  height: 100%;
  background-color: white;
  display: ${(props) => (props.display === 'none;' ? 'none;' : 'flex;')} | 'flex;';
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

const Title = styled.p`
  font-family: pretendard;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  color: ${({ theme }) => theme.color.modalText};
`

const ContentWrapper = styled.p`
  font-family: pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.32px;
  margin: 0;
  color: ${({ theme }) => theme.color.modalText};
`

interface ContentProps {
  text: string
}

const Content = ({ text }: ContentProps) => {
  const linedText = autoLineText(text)
  return <ContentWrapper>{linedText}</ContentWrapper>
}

const ConfirmButton = styled(BlackButtonComponent)`
  font-family: pretendard;
`

export const AuthCodeModal = (display: string) => {
  return (
    <Layout display={display}>
      <ModalWrapper>
        <Title>{'인증번호 발송 성공'}</Title>
        <Content text={`인증번호를 발송했습니다.\n메일함을 확인해 주세요.`}></Content>
        <ConfirmButton>{`확인`}</ConfirmButton>
      </ModalWrapper>
    </Layout>
  )
}

interface ModalProps {
  title: string
  text: string
  buttonText: string
  display: string
  setDisplay: React.Dispatch<React.SetStateAction<string>>
  setCurrentLocation?: React.Dispatch<React.SetStateAction<ILocation | undefined>>
}

export const Modal = ({ title, text, buttonText, display, setDisplay }: ModalProps) => {
  const navigate = useNavigate()

  return (
    <Layout display={display}>
      <ModalWrapper>
        <Title>{title}</Title>
        <Content text={text}></Content>
        <ConfirmButton
          onClick={() => {
            setDisplay('none;')
            if (title == `정상 신청 완료`) navigate('/', { state: 'home' })
          }}
        >
          {buttonText}
        </ConfirmButton>
      </ModalWrapper>
    </Layout>
  )
}

export const AgreeGeolocationModal = ({
                                        title,
                                        text,
                                        buttonText,
                                        display,
                                        setDisplay,
                                        setCurrentLocation
                                      }: ModalProps) => {
  const { geolocation } = navigator

  const onclick = async () => {
    setDisplay('none;')

    geolocation.getCurrentPosition(
      (pos) => {
        const { latitude, longitude } = pos.coords
        setCurrentLocation!({ latitude, longitude } as ILocation)
      },
      (err) => console.log(err.message),
      {
        enableHighAccuracy: false,
        timeout: 1000 * 5,
        maximumAge: 1000 * 3600 * 24
      }
    )
  }

  return (
    <AgreeGeolocationModalWrapper display={display}>
      <Layout display={'flex;'}>
        <ModalWrapper>
          <Title>{title}</Title>
          <Content text={text}></Content>
          <ConfirmButton onClick={onclick}>{buttonText}</ConfirmButton>
        </ModalWrapper>
      </Layout>
    </AgreeGeolocationModalWrapper>
  )
}
