import React from 'react'
import styled from 'styled-components'
import { BlackButton } from '../../components/Button'

const MakeWishWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  max-width: 350px;
`

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: -25px;
`

export const MakeWish = () => {
  return (
    <MakeWishWrapper>
      <ImagesWrapper>
        <img src={`/img/welcome_character1.svg`} />
        <img src={`/img/welcome_character2.svg`} />
      </ImagesWrapper>
      <BlackButton route={'/article'} value={'article'}>장승패밀리에게 소원빌기</BlackButton>
    </MakeWishWrapper>
  )
}
