import React, { useEffect, useState } from 'react'
import { Section } from '../../components/Section'
import styled from 'styled-components'
import { Welcome } from '../../components/Welcome'
import { AuthCard } from '../../components/AuthCard'
import { Modal } from '../../components/AuthCodeModal'
import { auth } from '../../firebase/config'
import { useAuthState } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'

const BackGround = styled.div``

const Layout = styled.div`
  padding: 0 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Login = () => {
  const authState = useAuthState()
  const navigate = useNavigate()

  const [display, setDisplay] = useState('none;')

  useEffect(() => {
    if (authState.state === 'loading') {
    } else if (authState.state === 'error') {
      console.log('authState error')
    } else if (authState.state === 'loaded') {
      if (authState.user) navigate('/', { replace: true })
    }
  }, [auth.currentUser])

  return (
    <Section bgcolor={`#FAF7F4`}>
      <BackGround>
        <Layout>
          <Welcome />
          <AuthCard setErrorDisplay={setDisplay} />
          <Modal
            title={`로그인 실패`}
            text={`올바르지 않은 이메일 혹은 비밀번호 입니다.\n 다시 시도해주세요.`}
            buttonText={`확인`}
            display={display}
            setDisplay={setDisplay}
          />
        </Layout>
      </BackGround>
    </Section>
  )
}
