import { TotemInfo } from './totemData'
import React, { SetStateAction } from 'react'
import styled from 'styled-components'
import { autoLineText } from '../../util/utils'
import { BlackButtonComponent } from '../../components/Button'

const ModalLayout = styled.div<{
  nameeng: string
  clickedvalue: string
}>`
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 768px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 auto;

  display: ${(props) =>
          props.nameeng === props.clickedvalue ? 'block;' : 'none;'};
  justify-items: center;
`
const ModalWrapper = styled.div`
  background-color: white;
  padding: 24px 16px 20px 16px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: 0 auto;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 16px;
  max-width: 310px;
  top: 15%;
`

const Title = styled.p`
  font-family: chosun;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  color: ${({ theme }) => theme.color.modalText};
`

const ContentWrapper = styled.p`
  font-family: chosun;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.32px;
  margin: 0;
  color: ${({ theme }) => theme.color.modalText};
`

const DescriptionWrapper = styled.p`
  font-family: chosun;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.32px;
  margin: 0;
  color: ${({ theme }) => theme.color.modalText};
`

const ConfirmButton = styled(BlackButtonComponent)`
  font-family: pretendard;
`

interface ContentProps {
  text: string
}

const Content = ({ text }: ContentProps) => {
  const linedText = autoLineText(text)
  return <ContentWrapper>{linedText}</ContentWrapper>
}

const Description = ({ text }: ContentProps) => {

  return (
    <DescriptionWrapper>
      {text}
    </DescriptionWrapper>
  )
}

const TotemImage = styled.img`
  width: 120px;
`

const OtherButton = ConfirmButton

const TotemModal = ({
                      totemInfo,
                      setSelectedValue,
                      setClickedValue,
                      clickedValue
                    }: {
  totemInfo: TotemInfo
  setSelectedValue: React.Dispatch<SetStateAction<string>>
  setClickedValue: React.Dispatch<SetStateAction<string>>
  clickedValue: string
}) => {

  return (
    <ModalLayout nameeng={totemInfo.nameEng} clickedvalue={clickedValue}>
      <ModalWrapper>
        <TotemImage src={`${totemInfo.img}`} />
        <Title>{`${totemInfo.nameKor} (${totemInfo.nameEng})`}</Title>
        <Description text={totemInfo.description} />
        <Content text={totemInfo.content}></Content>
        <ConfirmButton
          onClick={(e) => {
            setSelectedValue(totemInfo.nameEng)
            setClickedValue('')
          }}
        >
          {'선택하기'}
        </ConfirmButton>
        <OtherButton
          onClick={(e) => {
            setSelectedValue('')
            setClickedValue('')
          }}
        >
          {'다른 장승 선택하기'}
        </OtherButton>
      </ModalWrapper>
    </ModalLayout>
  )
}

export { TotemModal }
