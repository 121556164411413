import styled from 'styled-components'
import React, { SetStateAction, useEffect, useState } from 'react'
import { Divider } from '../../components/Divider'
import { ReceiveGoods } from './ReceiveGoods'
import { ChoiceGoods } from './ChoiceGoods'
import { SelectPrice } from './SelectPrice'
import { Welcome } from '../../components/Welcome'
import { BlackButton, BlackButtonComponent } from '../../components/Button'
import { useDonationState } from '../../context/DonationContext'
import { useAuthState } from '../../context/AuthContext'
import { auth } from '../../firebase/config'
import { useNavigate } from 'react-router-dom'
import { createDonation } from '../../firebase/donation'
import { getGoods, GetGoodsDto } from '../../firebase/goods'

const Background = styled.div`
  background-color: ${({ theme }) => theme.color.almostWhite};
`

const Layout = styled.div`
  height: 100%;
  max-width: 768px;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
`

const Notice = styled.div`
  color: #1b1b1c;
  font-family: chosun;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 10px 0 50px 0;
`

export const Goods = ({ setValidate, setConfirm, setDisplay, setDisplay2, setDisplay3 }: {
  setValidate: React.Dispatch<SetStateAction<boolean>>,
  setConfirm: React.Dispatch<SetStateAction<boolean>>,
  setDisplay: React.Dispatch<SetStateAction<string>>,
  setDisplay2: React.Dispatch<SetStateAction<string>>,
  setDisplay3: React.Dispatch<SetStateAction<string>>,
}) => {
  const [price, setPrice] = useState('')
  const [authLoad, setAuthLoad] = useState(true)
  const authState = useAuthState()

  const [filteredData, setFilteredData] =
    useState([{ name: '', img: '', content: '' }])
  const donationState = useDonationState()
  const navigate = useNavigate()

  const [goods, SetGoods] = useState([{}] as GetGoodsDto[])

  useEffect(() => {
    if (authState.state === 'loading') {
    } else if (authState.state === 'error') {
    } else if (authState.state === 'loaded') {
      if (!authState.user) setAuthLoad(false)
    }
  }, [auth.currentUser])

  useEffect(() => {
    getGoods().then(data => {
      if (data) {
        SetGoods(data)
        const convertedData = data.map((d) => {
          return { name: d.title, img: d.image.src, content: d.content }
        })
        setFilteredData(
          price === '3,000원' || price === '5,000원' || price === '7,000원'
            ? convertedData!.slice(0, 1)
            : price === '1,000원'
              ? convertedData!.slice(0, 0)
              : convertedData!
        )
      }
    })
  }, [])

  const onClick = async () => {
    await createDonation(auth.currentUser!.uid, donationState.donation)
      .then((res) => {
        res && navigate('/', { state: 'home' })
      })
  }

  return !authLoad ? (
    <Background>
      <Layout>
        <Welcome />
        <Notice>{`로그인하고 굿즈를 받아보세요.`}</Notice>
        <BlackButton route={'/login'}>{`로그인 하러 가기`}</BlackButton>
      </Layout>
    </Background>
  ) : (
    <Background>
      <SelectPrice setState={setPrice} price={price} />
      {
        price !== '1,000원' ?
          <>
            {filteredData.length !== 0 && <Divider />}
            <ChoiceGoods filteredData={filteredData} />
            <Divider />
            <ReceiveGoods setValidate={setValidate} setConfirm={setConfirm} setDisplay={setDisplay}
                          setDisplay2={setDisplay2} setDisplay3={setDisplay3} />
          </> :
          <Layout>
            <BlackButtonComponent onClick={onClick}>{`기부하기`}</BlackButtonComponent>
          </Layout>
      }
    </Background>
  )
}
