import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import './EventTabs.css'
import { Goods } from './Goods'
import { Festival } from './Festival'
import { News } from './News'
import styled from 'styled-components'
import { TitleBackGround } from '../location/location'
import React, { SetStateAction } from 'react'
import { DonationContextProvider } from '../../context/DonationContext'
import { FestivalContextProvider } from '../../context/FestivalContext'

const Layout = styled.div`
  max-width: 768px;
  margin: 0 auto;
  width: 100%;
`

export const EventTab = (
  {
    setValidate,
    setConfirm,
    setDisplay,
    setDisplay2,
    setDisplay3
  }: {
    setValidate: React.Dispatch<SetStateAction<boolean>>,
    setConfirm: React.Dispatch<SetStateAction<boolean>>,
    setDisplay: React.Dispatch<SetStateAction<string>>,
    setDisplay2: React.Dispatch<SetStateAction<string>>,
    setDisplay3: React.Dispatch<SetStateAction<string>>,
  }) => {

  return (
    <Tabs>
      <TitleBackGround>
        <Layout>
          <TabList>
            <Tab>굿즈 받기</Tab>
            <Tab>소원성취 행사</Tab>
            <Tab>장승패밀리 소식</Tab>
          </TabList>
        </Layout>
      </TitleBackGround>
      <TabPanel>
        <DonationContextProvider>
          <Goods setValidate={setValidate} setConfirm={setConfirm} setDisplay={setDisplay} setDisplay2={setDisplay2}
                 setDisplay3={setDisplay3} />
        </DonationContextProvider>
      </TabPanel>
      <FestivalContextProvider>
        <TabPanel>
          <Festival />
        </TabPanel>
        <TabPanel>
          <News />
        </TabPanel>
      </FestivalContextProvider>
    </Tabs>
  )
}
