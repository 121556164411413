import styled from 'styled-components'
import React, { Fragment, SetStateAction, useEffect, useState } from 'react'
import { mediaQuery } from '../../util/responsiveUtils'
import { Input, InputWrapper, Label, TextField } from '../../components/Input'
import { BlackButtonComponent } from '../../components/Button'
import { LocationCards } from '../../components/LocationCard'
import DaumPostcodeEmbed, { Address } from 'react-daum-postcode'
import { Spacing } from '../../components/Spacing'
import { useDonationState } from '../../context/DonationContext'
import { createDonation, PostDonationReq } from '../../firebase/donation'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../firebase/config'

const Layout = styled.div`
  padding: 0 20px;
  max-width: 768px;
  margin: 0 auto;
`

const SubjectWrapper = styled.div`
  padding: 20px 0 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`

const SubjectText = styled.p`
  color: #1b1b1c;
  margin: 0;

  font-family: chosun;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;
  flex-grow: 1;
  text-align: start;
`

const ReceiveButton = styled.button`
  font-family: pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  color: #5e6062;
  border: none;
  display: flex;
  padding: 4px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
`

type ReceiveType = 'direct' | 'post'
const ReceiveDirectButton = styled(ReceiveButton)<{ receivetype: ReceiveType }>`
  background-color: #dde0e4;
  opacity: ${(props) => (props.receivetype === 'direct' ? 1 : 0.5)};
`

const ReceiveMailPost = styled(ReceiveButton)<{ receivetype: ReceiveType }>`
  background-color: #ebe1cd;
  opacity: ${(props) => (props.receivetype === 'post' ? 1 : 0.5)};
`

const DestinationWrapper = styled.div`
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;

  ${mediaQuery('tablet')} {
    max-width: 768px;
  }
`

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 10px;
`

const AddressField = styled(TextField)`
  flex-basis: 70%;

  &:disabled {
    color: #868e96;
  }
`

const AddressSearchButton = styled(BlackButtonComponent)`
  flex-basis: 30%;
  font-family: pretendard;
`

const AddressModalWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  padding: 0;
`

const DaumWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
const postCodeStyle = {
  height: `420px`
}

const AddressModal = ({
                        setIsOpen,
                        setAddress,
                        setZipCode
                      }: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setAddress: React.Dispatch<React.SetStateAction<string>>
  setZipCode: React.Dispatch<React.SetStateAction<string>>
}) => {
  const onCompletePost = (data: Address) => {
    let fullAddress = data.address
    let extraAddress = ''

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }
    setIsOpen(false)
    setAddress(fullAddress)
    setZipCode(data.zonecode)
  }

  return (
    <AddressModalWrapper>
      <DaumWrapper>
        <DaumPostcodeEmbed autoClose style={postCodeStyle} onComplete={onCompletePost} />
      </DaumWrapper>
    </AddressModalWrapper>
  )
}

const AddressForm = ({
                       addressSetState,
                       addressState
                     }: {
  addressSetState: {
    setAddress: React.Dispatch<SetStateAction<string>>
    setZipCode: React.Dispatch<SetStateAction<string>>
    setExtraAddress: React.Dispatch<SetStateAction<string>>
  }
  addressState: {
    address: string
    extraAddress: string
  }
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const onToggleModal = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <InputWrapper>
      <Label>{`주소`}</Label>
      <FieldWrapper>
        <AddressField disabled placeholder={`주소를 검색해주세요.`} focus={'true'} value={addressState.address} />
        <AddressSearchButton onClick={onToggleModal}>{`주소 검색`}</AddressSearchButton>
      </FieldWrapper>
      <AddressField
        placeholder={`상세주소를 입력해주세요.`}
        focus={'true'}
        value={addressState.extraAddress}
        onChange={(e) => addressSetState.setExtraAddress(e.currentTarget.value)}
      />
      {isOpen && (
        <AddressModal
          setIsOpen={setIsOpen}
          setAddress={addressSetState.setAddress}
          setZipCode={addressSetState.setZipCode}
        />
      )}
    </InputWrapper>
  )
}

const GoodsPurpose = styled.p`
  margin: 24px 0 0 0;
  padding: 12px;
  font-family: chosun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.28px;
  word-break: keep-all;
`

export const ReceiveGoods = ({
                               setValidate,
                               setConfirm,
                               setDisplay,
                               setDisplay2,
                               setDisplay3
                             }: {
  setValidate: React.Dispatch<SetStateAction<boolean>>,
  setConfirm: React.Dispatch<SetStateAction<boolean>>,
  setDisplay: React.Dispatch<SetStateAction<string>>,
  setDisplay2: React.Dispatch<SetStateAction<string>>,
  setDisplay3: React.Dispatch<SetStateAction<string>>,
}) => {
  const [userName, setUserName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [address, setAddress] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [extraAddress, setExtraAddress] = useState('')

  const addressState = {
    address,
    extraAddress
  }
  const addressSetState = {
    setAddress: setAddress,
    setZipCode: setZipCode,
    setExtraAddress: setExtraAddress
  }

  const [receiveType, setReceiveType] = useState('direct' as ReceiveType)
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setReceiveType(e.currentTarget.value as ReceiveType)
  }

  const donationState = useDonationState()
  const navigate = useNavigate()

  useEffect(() => {
    if (receiveType !== 'direct') {
      donationState.setDonation({
        ...donationState.donation,
        receive: {
          name: userName,
          phoneNumber: phoneNumber,
          address: address,
          zipCode: zipCode,
          extraAddress: extraAddress
        }
      })
    }
  }, [userName, phoneNumber, address, zipCode, extraAddress])

  const validateForm = (donation: PostDonationReq) => {
    const type1 = donation.price !== '' && donation.goodsName !== ''
    const type2 = (): boolean => {
      if (receiveType === 'direct') {
        return donation.location ? !Object.values(donation.location).includes('') : false
      } else {
        return donation.receive ? !Object.values(donation.receive).includes('') : false
      }
    }
    return type1 && type2()
  }

  const onSubmit = async () => {
    const isValidated = validateForm(donationState.donation)
    setValidate(isValidated)

    if (isValidated) {
      await createDonation(auth.currentUser!.uid, donationState.donation).then((res) => {
        if (res) {
          receiveType === 'direct' ? setDisplay2('flex;') : setDisplay3('flex;')
          setConfirm(true)
        }
      })
    } else {
      setDisplay('flex;')
    }
  }

  return (
    <Layout>
      <SubjectWrapper>
        <SubjectText>{`굿즈 수령장소`}</SubjectText>
        <ReceiveDirectButton
          receivetype={receiveType}
          onClick={onClick}
          value={'direct'}
        >{`직접 수령`}</ReceiveDirectButton>
        <ReceiveMailPost receivetype={receiveType} onClick={onClick} value={'post'}>{`우편으로 받기`}</ReceiveMailPost>
      </SubjectWrapper>
      {receiveType === 'direct' ? (
        <Fragment>
          <Spacing height={`20px`} />
          <LocationCards selectable={'true'} />
          <Spacing height={`20px`} />
        </Fragment>
      ) : (
        <DestinationWrapper>
          <Input label={`수령자명`} placeHolder={`이름을 입력해주세요.`} value={userName} setState={setUserName} />
          <Input
            label={`휴대폰 번호`}
            placeHolder={`휴대폰 번호를 입력해주세요.`}
            value={phoneNumber}
            setState={setPhoneNumber}
          />
          <AddressForm addressState={addressState} addressSetState={addressSetState} />
        </DestinationWrapper>
      )}
      <BlackButtonComponent onClick={onSubmit}>{`굿즈 신청하기`}</BlackButtonComponent>
      <GoodsPurpose>{`기부금은 소원빌기 행사와 소원지 운영을 위해 사용됩니다.`}</GoodsPurpose>
    </Layout>
  )
}
