import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { countArticles } from '../../firebase/article'

const CurrentWishWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 20px 0;
  gap: 10px;
`

const WishText = styled.p`
  font-family: chosun;
  font-size: 24px;
`

const Today = () => {
  const todayText = moment(new Date()).tz('Asia/Seoul').format('YYYY-MM-DD') + ` 기준`

  const TodayWrapper = styled.p`
    font-family: pretendard;
    font-size: 14px;
    color: ${({ theme }) => theme.color.text1};
  `

  return <TodayWrapper>{todayText}</TodayWrapper>
}

const Counter = () => {
  const CounterWrapper = styled.div`
    display: flex;
    flex-direction: row;
  `

  const [counterImages, setCounterImages] = useState([<img key={1}></img>])

  const makeCountNumber = (count: number) => {
    return count
      .toString()
      .padStart(10, '0')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      .split('')
  }

  const letterImages = (letters: string[]) => {
    return letters.map((letter, index) => {
      return <img src={`/img/count/count-${letter}.svg`} alt={``} key={index} />
    })
  }

  useEffect(() => {
    countArticles().then(makeCountNumber).then(letterImages).then(setCounterImages)
  }, [])

  return <CounterWrapper>{counterImages}</CounterWrapper>
}

export const CurrentWish = () => {
  return (
    <CurrentWishWrapper>
      <WishText>현재까지 장승이 받은 소원</WishText>
      <Today />
      <Counter />
    </CurrentWishWrapper>
  )
}
