type TwoLocation = {
  lat1: number
  lon1: number
  lat2: number
  lon2: number
}

export const distance = ({ lat1, lon1, lat2, lon2 }: TwoLocation) => {
  const R = 6371000 // 지구 반지름 (단위: km)
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  // 두 지점 간의 거리 (단위: km)
  return R * c
}

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180)
}

