import { PostArticleDto } from '../firebase/article'
import React, { createContext, SetStateAction, useContext, useState } from 'react'

type ArticleState = {
  article: PostArticleDto
  setArticle: React.Dispatch<SetStateAction<PostArticleDto>>
}

const ArticleContext = createContext<ArticleState>({} as ArticleState)

export const ArticleContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [article, setArticle] = useState({
    totemName: '',
    title: '',
    content: '',
    locationId: '',
  } as PostArticleDto)

  return <ArticleContext.Provider value={{ article, setArticle }}>{children}</ArticleContext.Provider>
}

export const useArticleState = () => {
  const article = useContext(ArticleContext)
  if (!article) throw new Error('ArticleProvider not found')
  return article
}
