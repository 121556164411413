import { db } from './config'
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore'
import { CreateField, getCurrentTimestamp, returnFalse } from './common'

interface ArticleDto {
  totemName: string
  title: string
  content: string
  locationId: string
}

interface PostArticleDto extends ArticleDto, CreateField {
  userId: string
}

interface GetArticleDto extends PostArticleDto {
  id: string
}

const getArticlesByUserUid = async (userId: string): Promise<GetArticleDto[] | undefined> => {
  if (userId === '') return undefined

  const q = query(collection(db, 'articles'), where('createdby', '==', userId))
  return await getDocs(q).then((snapshot) => {
    return snapshot.empty
      ? undefined
      : snapshot.docs.map((d) => {
        return {
          id: d.id,
          ...d.data()
        } as GetArticleDto
      })
  })
}

const createArticle = async (userId: string, article: PostArticleDto): Promise<boolean> => {
  const now = getCurrentTimestamp()
  return await addDoc(collection(db, 'articles'), {
    ...article,
    createdate: now,
    lastupdate: now,
    createdby: userId,
    updatedby: userId,
    userId: userId
  } as PostArticleDto)
    .then((snapshot) => {
      return !!snapshot
    })
    .catch(returnFalse)
}

const countArticles = async () => {
  const articlesRef = collection(db, 'articles')
  return await getDocs(articlesRef)
    .then((snapshot) => {
      return snapshot.size
    })
    .catch((err) => {
      console.log(err.message)
      return 0
    })
}

export type { PostArticleDto, ArticleDto, GetArticleDto }
export { countArticles, createArticle, getArticlesByUserUid }
