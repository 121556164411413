import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import { auth, db } from './config'
import { mapError, ResponseDto } from './common'
import { and, collection, getDocs, query, where } from 'firebase/firestore'

interface GetAuthDto {
  email: string
}

interface CreateAuthDto extends GetAuthDto {
  password: string
}

type SignInReq = CreateAuthDto

type MatchReq = GetAuthDto & {
  authCode: string
}

const createAuth = async ({ email, password }: CreateAuthDto): Promise<ResponseDto> => {
  return await createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      return { body: userCredential.user.uid }
    })
    .catch((Error) => mapError(Error))
}

const signInAuth = async ({ email, password }: SignInReq): Promise<ResponseDto> => {
  return await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      return { body: userCredential.user }
    })
    .catch((Error) => mapError(Error))
}

const matchEmailAndAuthCode = async ({ email, authCode }: MatchReq): Promise<boolean> => {
  const q = query(collection(db, 'auth'), and(where('email', '==', email), where('code', '==', authCode)))
  return await getDocs(q).then((snapshot) => {
    return !snapshot.empty
  })
}

onAuthStateChanged(auth, (u) => {
  if (u) {
    console.log('user on')
  } else {
    console.log('user off')
  }
})

export { createAuth, signInAuth, matchEmailAndAuthCode }
export type { SignInReq, MatchReq }
