import React, { Fragment, useState } from 'react'
import { Section } from '../../components/Section'
import { Subject } from '../../components/Subject'
import styled from 'styled-components'
import { Input } from '../../components/Input'
import { Modal } from '../../components/AuthCodeModal'
import { BlackButton, BlackButtonComponent, WhiteButtonComponent } from '../../components/Button'
import { mediaQuery } from '../../util/responsiveUtils'
import { existUser, signUp } from '../../firebase/user'
import { sendMail } from '../../firebase/authCode'
import { matchEmailAndAuthCode } from '../../firebase/auth'
import { auth } from '../../firebase/config'
import { signOut } from 'firebase/auth'

const BackGround = styled.div`
  padding-bottom: 80px;
  overflow: hidden;
  touch-action: none;
`

const Layout = styled.div`
  padding: 0 20px;
  flex-direction: column;
  justify-content: start;
  display: flex;
  margin: 0 auto;

  ${mediaQuery('tablet')} {
    max-width: 768px;
  }
`

const AuthCodeButton = styled(WhiteButtonComponent)`
  width: 50%;
  align-self: end;
  margin: 5px 0 5px 0;
  border: 1px solid ${({ theme }) => theme.color.grey40};
  font-family: pretendard;
`

const SendEmailButton = styled(BlackButtonComponent)`
  width: 50%;
  align-self: end;
  margin: 5px 0 5px 0;
  font-family: pretendard;
`

const ConfirmedButton = styled(BlackButtonComponent)`
  width: 50%;
  align-self: end;
  margin: 5px 0 5px 0;
  background: ${({ theme }) => theme.color.grey30};
  color: ${({ theme }) => theme.color.grey60};
  cursor: none;
  font-family: pretendard;
`

const BlackButtonWrapper = styled.div`
  bottom: 0;
  justify-self: end;
  position: fixed;
  padding: 16px 20px;
  width: calc(100% - 40px);
  //display: none;
`

const CompleteLayout = styled.div<{ display: string }>`
  background-color: #FAF7F4;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 768px;
  height: 100vh;
  padding: 0 20px;
  display: ${(props) => `${props.display}`}
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  gap: 40px;
  z-index: 1;
`

const ContentWrapper = styled.p`
  font-family: chosun;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.32px;
  margin: 0;
  color: ${({ theme }) => theme.color.grey80};
`

const Content = ({ text }: { text: string }) => {
  const data = text.split('\n').map((txt, index) => (
    <Fragment key={index}>
      {txt}
      <br />
    </Fragment>
  ))
  return <ContentWrapper>{data}</ContentWrapper>
}

const CompleteImage = styled.div`
  width: 260px;
`

const ButtonLayout = styled.div`
  position: fixed;
  bottom: 0;
  max-width: 768px;
  margin: 0 auto;
  left: 0;
  right: 0;
  padding: 0 20px 20px 20px;
  z-index: 0;
`

const SignUpButton = styled(BlackButtonComponent)``

export const SignUp = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [name, setName] = useState('')
  const [birth, setBirth] = useState('')
  const [authCode, setAuthCode] = useState('')

  const onClickSendMail = async () => {
    const isExist = await existUser({ email })
    isExist ? setExistModalDisplay('flex;') : await sendMail(email).then(() => setAuthCodeModalDisplay('flex;'))
    setAuthProgress('auth')
  }

  const onClickAuthCode = async () => {
    if (await matchEmailAndAuthCode({ email, authCode })) {
      setDisable(true)
      setAuthProgress('complete')
    } else {
      setFailModalDisplay('flex;')
    }
  }

  const onClickSignUp = async () => {
    const validate = validateForm()
    if (validate) {
      const res = await signUp({ email, password, name, birth })
      if (typeof res === 'object') {
        throw new Error(res.error.msg)
      } else {
        await signOut(auth)
        setDisplay('flex;')
      }
    } else {
      setSignUpFailModalDisplay('flex;')
    }
  }

  const validateForm = () => {
    return (
      email.includes('@') &&
      email.includes('.') &&
      authProgress === 'complete' &&
      password.length >= 8 &&
      password2.length >= 8 &&
      password === password2 &&
      name.length >= 1 &&
      birth.length === 6
    )
  }

  const [existModalDisplay, setExistModalDisplay] = useState('none;')
  const [authCodeModalDisplay, setAuthCodeModalDisplay] = useState('none;')
  const [failModalDisplay, setFailModalDisplay] = useState('none;')
  const [signUpFailModalDisplay, setSignUpFailModalDisplay] = useState('none;')

  const [authProgress, setAuthProgress] = useState('beforeAuth')
  const [disable, setDisable] = useState(false)
  const [display, setDisplay] = useState('none;')

  return (
    <Section bgcolor={`#FAF7F4`}>
      <BackGround>
        <Subject text={`회원가입`}></Subject>
        <Layout>
          <Input
            label={`이메일`}
            placeHolder={`이메일주소를 입력해주세요.`}
            inputType={'email'}
            value={email}
            setState={setEmail}
            required={true}
            invalidText={'이메일주소를 입력해주세요.'}
            disabled={disable}
          ></Input>
          {authProgress === 'beforeAuth' ? (
            <AuthCodeButton onClick={onClickSendMail}>{`인증번호 발송`}</AuthCodeButton>
          ) : authProgress === 'auth' ? (
            <>
              <Input value={authCode} inputType={'text'} setState={setAuthCode} required={true} />
              <SendEmailButton onClick={onClickAuthCode} disabled={disable}>{`이메일 인증하기`}</SendEmailButton>
            </>
          ) : authProgress === 'complete' ? (
            <ConfirmedButton disabled={disable}>{`인증완료`}</ConfirmedButton>
          ) : null}

          <Input
            label={`비밀번호`}
            placeHolder={`비밀번호를 8자 이상 입력해주세요.`}
            inputType={'password'}
            value={password}
            setState={setPassword}
            required={true}
            minLength={8}
            invalidText={
              password.length === 0
                ? `비밀번호를 입력해주세요.`
                : password.length < 8
                ? `비밀번호를 8자 이상 입력해주세요.`
                : ''
            }
          ></Input>
          <Input
            placeHolder={`비밀번호를 한번 더 입력해주세요.`}
            inputType={'password'}
            value={password2}
            setState={setPassword2}
            required={true}
            minLength={8}
            same={password}
            invalidText={
              password2 === ''
                ? `비밀번호를 한번 더 입력해주세요.`
                : password !== password2
                ? `비밀번호가 일치하지 않습니다`
                : password2!.length < 8
                ? `비밀번호를 8자 이상 입력해주세요.`
                : ''
            }
          ></Input>
          <Input
            label={`이름`}
            placeHolder={`이름을 입력해주세요.`}
            inputType={'name'}
            setState={setName}
            value={name}
            invalidText={`이름을 입력해주세요.`}
            required={true}
          ></Input>
          <Input
            label={`생년월일 6자리`}
            placeHolder={`6자리로 입력해 주세요 예:  960101`}
            inputType={'text'}
            maxLength={6}
            value={birth}
            invalidText={`생년월일을 입력해주세요`}
            setState={setBirth}
            required={true}
          ></Input>
        </Layout>
      </BackGround>

      <Modal
        title={`인증번호 발송 성공`}
        text={`인증번호를 발송했습니다.\n메일함을 확인해 주세요.`}
        buttonText={`확인`}
        display={authCodeModalDisplay}
        setDisplay={setAuthCodeModalDisplay}
      />
      <Modal
        title={`중복된 이메일`}
        text={`해당 이메일로 이미 가입이 되어있습니다.`}
        buttonText={`확인`}
        display={existModalDisplay}
        setDisplay={setExistModalDisplay}
      />
      <Modal
        title={`메일 인증 실패`}
        text={`메일과 인증코드가 올바르지 않습니다.`}
        buttonText={`확인`}
        display={failModalDisplay}
        setDisplay={setFailModalDisplay}
      />
      <Modal
        title={`회원가입 실패`}
        text={`회원정보를 올바르게 입력해주세요.`}
        buttonText={`확인`}
        display={signUpFailModalDisplay}
        setDisplay={setSignUpFailModalDisplay}
      />

      <CompleteLayout display={display}>
        <Subject text={`회원가입 완료`} align={'center;'}></Subject>
        <Content text={`환영합니다! 장승에게 소원빌고\n꿈을 이루어 보세요.`}></Content>
        <CompleteImage>
          <img src={`/img/complete_signup.svg`} />
        </CompleteImage>
        <BlackButton route={'/login'}>{`로그인 하러 가기`}</BlackButton>
      </CompleteLayout>

      <ButtonLayout>
        <SignUpButton onClick={onClickSignUp}>{`회원가입 하기`}</SignUpButton>
      </ButtonLayout>
    </Section>
  )
}
