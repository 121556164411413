import styled, { css } from 'styled-components'
import React from 'react'
import { useDonationState } from '../../context/DonationContext'
import { Spacing } from '../../components/Spacing'

const Layout = styled.div`
  padding: 0 20px;
`

const SelectPriceWrapper = styled.div`
  max-width: 768px;
  margin: 0 auto;
`

const Title = styled.p`
  padding-top: 20px;
  padding-bottom: 8px;
  text-align: start;
  margin: 0;
`

const Notice = styled.p`
  text-align: start;
  color: #f17e7e;
  font-family: chosun;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.14px;
  margin: 0 0 8px 0;
`

const prices = ['1,000원', '3,000원', '5,000원', '7,000원', '10,000원']

const CardWrapper = styled.button<{ text: string; price: string }>`
  margin: 0 auto;
  width: 100%;
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  outline: none;

  ${(props) =>
          !(props.price === props.text)
                  ? css`
                    border: 1px solid var(--gray-40, #ced4da);
                    background: var(--gray-00, #fff);
                    color: var(--gray-60, #868e96);
                  `
                  : css`
                    border: none;
                    background: var(--green, #739c61);
                    color: var(--gray-00, #fff);
                  `}

  font-family: chosun;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
`

const CardGrid = styled.div<{ text: string }>`
  grid-column: ${(props) => {
    if (props.text === '7,000원' || props.text === '10,000원') return `span 3 / span 3`
    else return 'span 2 / span 2'
  }};
`

const PriceCard = ({
                     text,
                     setState,
                     price
                   }: {
  text: string
  setState: React.Dispatch<React.SetStateAction<string>>
  price: string
}) => {
  const donationState = useDonationState()

  const onClick = () => {
    setState(text)
    donationState.setDonation({ ...donationState.donation, price: text })
  }

  return (
    <CardGrid text={text}>
      <CardWrapper text={text} price={price} onClick={onClick}>
        <p style={{ margin: 0 }}>{text}</p>
      </CardWrapper>
    </CardGrid>
  )
}

const PriceCards = ({ setState, price }: { setState: React.Dispatch<React.SetStateAction<string>>; price: string }) => {
  const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 200px));
    width: 100%;
    padding: 12px 0 16px 0;
    gap: 12px 10px;
  `

  const cards = prices.map((p, index) => {
    return <PriceCard key={index} text={p} setState={setState} price={price} />
  })

  return (
    <GridWrapper>
      <>{cards}</>
    </GridWrapper>
  )
}

const AccountNotice = styled.span`
  font-size: 13.5px;
`

export const SelectPrice = ({
                              setState,
                              price
                            }: {
  setState: React.Dispatch<React.SetStateAction<string>>
  price: string
}) => {
  return (
    <Layout>
      <SelectPriceWrapper>
        <Title>{`기부금액 선택`}</Title>
        <Notice>{`3,000원 이상은 굿즈를 받을 수 있어요!`}</Notice>
        <PriceCards setState={setState} price={price} />
        <AccountNotice>{`농협 계좌번호:301-5312-6111-41, 에이텍그룹코리아`}</AccountNotice>
        <Spacing height={'8px'} />
      </SelectPriceWrapper>
    </Layout>
  )
}
