import { LocationInfo, PostDonationReq, ReceiveInfo } from '../firebase/donation'
import React, { createContext, SetStateAction, useContext, useState } from 'react'

export type DonationState = {
  donation: PostDonationReq
  setDonation: React.Dispatch<SetStateAction<PostDonationReq>>
}

const DonationContext = createContext<DonationState>({} as DonationState)

export const DonationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [donation, setDonation] = useState({
    price: '',
    goodsName: '',
    receive: {
      name: '',
      phoneNumber: '',
      address: '',
      zipCode: '',
      extraAddress: '',
    } as ReceiveInfo,
    location: {
      name: '',
      address: '',
    } as LocationInfo,
  } as PostDonationReq)

  return <DonationContext.Provider value={{ donation, setDonation }}>{children}</DonationContext.Provider>
}

export const useDonationState = () => {
  const donation = useContext(DonationContext)
  if (!donation) throw new Error('DonationProvider not found')
  return donation
}
