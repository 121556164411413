import React, { ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

const breakpoints = {
  mobile: 360,
  tablet: 768
}
export const mediaQuery = (media: 'mobile' | 'tablet') => `@media (min-width: ${breakpoints[media]}px)`

// export const Mini: React.FC<{ children: ReactNode }> = ({ children }) => {
//   const isMini = useMediaQuery({ query: '(max-width:374px)' })
//   return isMini ? <>{children}</> : null
// }

export const Mobile: React.FC<{ children: ReactNode }> = ({ children }) => {
  const isMobile = useMediaQuery({ query: '(max-width:767px)' })
  return isMobile ? <>{children}</> : null
}

export const Tablet: React.FC<{ children: ReactNode }> = ({ children }) => {
  const isTablet = useMediaQuery({ query: '(min-width: 768px)' })
  return isTablet ? <>{children}</> : null
}

// export const Desktop: React.FC<{ children: ReactNode }> = ({ children }) => {
//   const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' })
//   return isDesktop ? <>{children}</> : null
// }
