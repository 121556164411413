import { returnUndefined } from './common'
import { db } from './config'
import { collection, getDocs } from 'firebase/firestore'

interface LocationDto {
  address: string
  name: string
  latitude: number
  longitude: number
  tel?: string
}

interface GetLocationDto extends LocationDto {
  id: string
}

const getLocations = async (): Promise<GetLocationDto[] | undefined> => {
  return await getDocs(collection(db, 'locations'))
    .then((snapshot) => {
      return snapshot.docs.map((doc) => {
        const docData = doc.data()
        const location = {
          id: doc.id,
          ...docData,
        } as GetLocationDto
        return location
      })
    })
    .catch(returnUndefined)
}

export type { GetLocationDto }
export { getLocations }
