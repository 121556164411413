import React from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import theme from './theme'
import { ThemeProvider } from 'styled-components'
import { Home } from './pages/home/home'
import { LocationsContextProvider } from './context/LocationContext'
import { AuthContextProvider } from './context/AuthContext'
import { Login } from './pages/login/login'
import { SignUp } from './pages/signUp/signUp'
import { Article } from './pages/article/article'
import { MyPage } from './pages/myPage/myPage'
import { Event } from './pages/event/event'
import { Location } from './pages/location/location'
import { NewsDetails } from './pages/event/NewsDetails'
import { FestivalContextProvider } from './context/FestivalContext'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <FestivalContextProvider>
          <AuthContextProvider>
            <LocationsContextProvider>
              <div className='App'>
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/signUp' element={<SignUp />} />

                  <Route path='/article' element={<Article />} />


                  <Route path='/event' element={<Event />} />
                  <Route path='/event/:id' element={<NewsDetails />} />


                  <Route path='/location' element={<Location />} />
                  <Route path='/myPage' element={<MyPage />} />
                </Routes>
              </div>
            </LocationsContextProvider>
          </AuthContextProvider>
        </FestivalContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
