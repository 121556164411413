import React, { useState } from 'react'
import { Section } from '../../components/Section'
import styled from 'styled-components'
import { SubjectWithBack } from '../../components/Subject'
import { BottomNavigation } from '../../components/BottomNavigation'
import { EventTab } from './EventTab'
import { BackButton } from '../../components/Button'
import { TitleBackGround } from '../location/location'
import { Modal } from '../../components/AuthCodeModal'

const Layout = styled.div`
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: chosun;
`

export const Event = () => {
  const [validate, setValidate] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [display, setDisplay] = useState('none;')
  const [display2, setDisplay2] = useState('none;')
  const [display3, setDisplay3] = useState('none;')

  return (
    <Section bgcolor={`#F8F8F8`}>
      <Layout>
        <TitleBackGround>
          <SubjectWithBack text={`이벤트`} fontSize={`18px`}>
            <BackButton>
              <img src={`./img/back_icon.svg`} />
            </BackButton>
          </SubjectWithBack>
        </TitleBackGround>
        <EventTab setValidate={setValidate} setConfirm={setConfirm} setDisplay={setDisplay} setDisplay2={setDisplay2}
                  setDisplay3={setDisplay3} />
      </Layout>
      <BottomNavigation />
      {!validate && (
        <Modal
          title={'굿즈 받기 작성 실패'}
          text={'모든 정보를 입력해주세요.'}
          buttonText={'확인'}
          display={display}
          setDisplay={setDisplay}
        />
      )}
      {
        confirm && (
          display2 === 'flex;' ? (
            <Modal
              title={'정상 신청 완료'}
              text={'선택하신 장소로 방문하여 물품 수령 부탁드립니다.'}
              buttonText={'확인'}
              display={display2}
              setDisplay={setDisplay2}
            />) : (
            display3 === 'flex;' ? (
              <Modal
                title={'정상 신청 완료'}
                text={'접수되었습니다.'}
                buttonText={'확인'}
                display={display3}
                setDisplay={setDisplay3}
              />
            ) : <></>
          )
        )
      }
    </Section>
  )
}
