import styled from 'styled-components'
import { mediaQuery } from '../../util/responsiveUtils'
import React, { SetStateAction, useEffect, useState } from 'react'
import { totemInfos } from './totemData'
import { TotemSlider } from './TotemSlider'
import { TotemModal } from './TotemModal'
import { useArticleState } from '../../context/ArticleContext'

const Layout = styled.div`
  width: 100%;
`

const SelectTotemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 16px 20px;
  margin: 0 auto;

  ${mediaQuery('tablet')} {
    max-width: 768px;
  }
`

const Title = styled.div`
  text-align: start;
  color: var(--gray-90, #3c3c3c);

  font-family: chosun;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const ModalArea = ({
                     setSelectedValue,
                     setClickedValue,
                     clickedValue
                   }: {
  setSelectedValue: React.Dispatch<SetStateAction<string>>
  setClickedValue: React.Dispatch<SetStateAction<string>>
  clickedValue: string
}) => {
  const modals = totemInfos.map((d, index) => {
    return (
      <TotemModal
        key={index}
        totemInfo={d}
        setSelectedValue={setSelectedValue}
        setClickedValue={setClickedValue}
        clickedValue={clickedValue}
      ></TotemModal>
    )
  })

  return <>{modals}</>
}

export const SelectTotem = () => {
  const [selectedValue, setSelectedValue] = useState('')
  const [clickedValue, setClickedValue] = useState('')

  const articleState = useArticleState()
  useEffect(() => {
    articleState.setArticle({ ...articleState.article, totemName: selectedValue })
  }, [selectedValue])

  return (
    <Layout>
      <SelectTotemWrapper>
        <Title>{`수호신 장승 선택하기`}</Title>
        <TotemSlider setClickedValue={setClickedValue} selectedValue={selectedValue} />
      </SelectTotemWrapper>
      <ModalArea setSelectedValue={setSelectedValue} setClickedValue={setClickedValue} clickedValue={clickedValue} />
    </Layout>
  )
}
