import styled, { css } from 'styled-components'
import React, { SetStateAction, useState } from 'react'

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 0;
`

export const Label = styled.label`
  align-self: start;
  font-family: pretendard;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.grey90};
  padding: 0 0 8px 4px;
`

export const TextField = styled.input<{ invalid?: string; focus: string }>`
  padding: 16px;
  border-radius: 6px;
  background: white;
  outline: none;

  font-family: pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;
  border: 1px solid #e9ecef;

  ${(props) => {
    if (props.focus === 'true') {
      if (props.invalid === 'true') {
        return css`
          border: 2px solid var(--Red, #ff6b6b);
        `
      } else {
        return css`
          &:invalid {
            border: 2px solid var(--Red, #ff6b6b);
          }

          &:valid {
            border: 1px solid #e9ecef;
          }
        `
      }
    }
  }}
  &::placeholder {
    color: ${({ theme }) => theme.color.grey50};
  }
`
export const InvalidTextWrapper = styled.div<{ invalid?: string; focus: string }>`
  color: var(--Red, #FF6B6B);
  padding: 4px 4px 0 4px;
  text-align: start;
  display: ${(props) => (props.focus === 'true' && props.invalid === `true` ? 'block;' : 'none;')}

  /* 12/medium */
  font-family: pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
  letter-spacing: -0.24px;
`

const InvalidText = ({ text, invalid, focus }: { text: string; invalid: string; focus: string }) => {
  return (
    <InvalidTextWrapper invalid={invalid} focus={`${focus}`}>
      {text}
    </InvalidTextWrapper>
  )
}

interface InputProps {
  label?: string
  placeHolder?: string
  inputType?: 'email' | 'password' | 'name' | 'number' | 'text'
  invalidText?: string
  value: string
  setState: React.Dispatch<React.SetStateAction<string>>
  required?: boolean
  minLength?: number
  maxLength?: number
  regexp?: string
  disabled?: boolean
  same?: string
}

const onChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setInvalid: React.Dispatch<SetStateAction<boolean>>,
  setState: React.Dispatch<SetStateAction<string>>,
  label?: string,
  same?: string,
) => {
  setState(e.currentTarget.value)
  if (label == `생년월일 6자리`) {
    e.currentTarget.value.length < 6 ? setInvalid(true) : setInvalid(!e.currentTarget.validity.valid)
  } else if (same != null) {
    same !== e.currentTarget.value ? setInvalid(true) : setInvalid(!e.currentTarget.validity.valid)
  } else setInvalid(!e.currentTarget.validity.valid)
}

export const Input = ({
  label,
  placeHolder,
  inputType,
  invalidText = '',
  value,
  setState,
  required = false,
  minLength,
  maxLength,
  regexp,
  disabled,
  same,
}: InputProps) => {
  const [invalid, setInvalid] = useState(true)
  const [focus, setFocus] = useState(false)

  return (
    <InputWrapper>
      <Label>{label}</Label>
      <TextField
        key={`${label}${inputType}${placeHolder}`}
        required={required}
        disabled={disabled}
        value={value}
        placeholder={placeHolder}
        type={inputType}
        onChange={(e) => {
          onChange(e, setInvalid, setState, label, same)
        }}
        minLength={minLength}
        invalid={`${invalid}`}
        maxLength={maxLength}
        onFocus={() => setFocus(true)}
        focus={`${focus}`}
        pattern={regexp}
      ></TextField>
      <InvalidText invalid={`${invalid}`} text={invalidText} focus={`${focus}`} />
    </InputWrapper>
  )
}
