import React, { SetStateAction } from 'react'
import { totemInfos } from './totemData'
import styled from 'styled-components'

const TotemList = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 6px;
  background-color: #f8f8f8;
`

const TotemButton = styled.button<{ selectedvalue: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;
  border: none;
  min-width: 100px;
  max-width: 100px;
  background-color: #f8f8f8;
  gap: 8px;

  filter: ${(props) => (props.value !== props.selectedvalue ? `grayscale(100%)` : `none`)};

  & > img {
    opacity: ${(props) => (props.value !== props.selectedvalue ? `0.5` : `none`)};
  }
`

const ContentWrapper = styled.div`
  padding: 6px;
  width: 100%;
  border-radius: 2.091px;
  background: var(--gray-00, #fff);
  box-shadow: 0 1.59307px 4.14199px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;

  & > img {
    padding: 8px;
    border-radius: 2px;
    border: 0.5px solid var(--gray-70, #495057);
    margin-bottom: -30px;
  }
`

const TotemTitle = styled.div`
  display: flex;
  width: 72px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 0 0 2px 2px;
  border: 0.5px solid var(--gray-90, #212529);
  background: var(--gray-00, #fff);

  font-family: chosun;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const TotemSlider = ({
                       setClickedValue,
                       selectedValue
                     }: {
  setClickedValue: React.Dispatch<SetStateAction<string>>
  selectedValue: string
}) => {
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setClickedValue(e.currentTarget.value)
  }

  const totemButtons = totemInfos.map((d) => {
    return (
      <TotemButton key={d.nameEng} value={d.nameEng} selectedvalue={selectedValue} onClick={onClick}>
        <ContentWrapper>
          <img src={`${d.img}`} width={72} height={91} />
          <TotemTitle>{d.nameKor}</TotemTitle>
        </ContentWrapper>
        <img src={`./img/check.svg`} />
      </TotemButton>
    )
  })

  return <TotemList>{totemButtons}</TotemList>
}

export { TotemSlider }
