import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import Dropdown, { Option } from 'react-dropdown'
import './WriteArticle.css'
import { mediaQuery } from '../../util/responsiveUtils'
import { InputNew } from '../../components/InputNew'
import { BeatLoader } from 'react-spinners'
import { useLocations } from '../../context/LocationContext'
import { useArticleState } from '../../context/ArticleContext'
import { ILocation } from '../../location/useGeoLocation'
import { distance } from '../../location/distance'
import { GetLocationDto } from '../../firebase/location'
import { AgreeGeolocationModal } from '../../components/AuthCodeModal'

const Layout = styled.div`
  width: 100%;
`

const WriteArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 16px 20px;
  margin: 0 auto;
  text-align: start;

  ${mediaQuery('tablet')} {
    max-width: 768px;
  }
`

const Title = styled.div`
  color: var(--gray-90, #3c3c3c);
  font-family: chosun;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const ArticleWrapper = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 11px;
`

const SelectLocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;

  font-family: pretendard;
`

const QrButton = styled.button`
  height: 40px;
  padding: 8px 12px;
  border-radius: 49px;
  border: none;
  background: #000;
  color: #fff;
  text-align: center;
  flex-basis: 30%;

  font-family: pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 120% */
  letter-spacing: -0.3px;
`

const TextArea = styled.textarea`
  height: 80px;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid var(--gray-20, #e9ecef);
  background: white;
  outline: none;

  color: var(--text-01, #212529);

  font-family: pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;

  &::placeholder {
    color: ${({ theme }) => theme.color.grey50};
    font-family: pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    letter-spacing: -0.32px;
  }
`

const LoaderWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  margin: 0 auto;
  height: 100%;
  background-color: white;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

const Content = styled.div`
  padding: 20px;
  font-family: pretendard;
  font-size: 20px;
  color: black;
`

const Loader = () => {
  return (
    <LoaderWrapper>
      <Content>{`위치 데이터를 받아오는 중입니다.`}</Content>
      <BeatLoader size={20} />
    </LoaderWrapper>
  )
}

type LocationDto = GetLocationDto & {
  distance?: number
}

interface LocationOption extends Option {
  label: React.ReactNode
  value: string
  data: {
    id: string
  }
}

export const WriteArticle = () => {
  const [loading, setLoading] = useState(true)
  const [currentLocation, setCurrentLocation] = useState(undefined as ILocation | undefined)
  const [showLocations, setShowLocations] = useState([{}] as LocationOption[])
  const [display, setDisplay] = useState('flex;')

  const locations = useLocations()

  useEffect(() => {
    if (currentLocation?.latitude !== undefined) {
      const calcLocations = locations
        .map((location) => {
          return {
            ...location,
            distance: distance({
              lat1: currentLocation.latitude,
              lon1: currentLocation.longitude,
              lat2: location!.latitude,
              lon2: location!.longitude
            })
          } as LocationDto
        })
        .sort((a, b) => b.distance! - a.distance!)
        .map((d) => {
          const text = `${d.name} (${(d.distance! / 1000).toFixed()}km)`
          return {
            label: text,
            value: text,
            data: { id: d.id }
          } as LocationOption
        })
        .slice(0, 5)
      setShowLocations(calcLocations)
    }
  }, [locations, currentLocation])

  useEffect(() => {
    showLocations.length !== 1 && setLoading(false)
  }, [showLocations])

  function getCalcLocations() {
    return locations && locations
      .map((d) => {
        const text = `${d.name}`
        return {
          label: text,
          value: text,
          data: { id: d.id }
        } as LocationOption
      })
      .slice(0, 5)
  }

  useEffect(() => {
    setTimeout(() => {
      if (!currentLocation) {
        const calcLocations = getCalcLocations()
        setShowLocations(calcLocations)
        setLoading(true)
      }
    }, 5000)
  })

  const [title, setTitle] = useState('')
  const articleState = useArticleState()

  const onChange = (e: Option) => {
    const locationId = showLocations.find((l) => l.value === e.value)?.data.id || ''
    articleState.setArticle({ ...articleState.article, locationId })
  }

  const onChangeContent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    articleState.setArticle({ ...articleState.article, content: e.currentTarget.value })
  }

  return (
    <Layout>
      {loading && <Loader />}
      <WriteArticleWrapper>
        <Title>{`위치 선택하기`}</Title>
        <ArticleWrapper>
          <SelectLocationWrapper>
            <Dropdown options={showLocations} onChange={onChange} placeholder='위치를 선택해주세요.' />
          </SelectLocationWrapper>
          <InputNew placeHolder={`소원 제목을 입력해주세요.`} value={title} setState={setTitle} />
          <TextArea placeholder={`소원 내용을 입력해주세요.`} onChange={onChangeContent} />
        </ArticleWrapper>
      </WriteArticleWrapper>
      <AgreeGeolocationModal
        title={'위치정보 확인'}
        text={'가까운 소원지를 찾습니다. 위치정보를 동의하고 확인버튼을 눌러주세요.'}
        buttonText={'확인'}
        display={display}
        setDisplay={setDisplay}
        setCurrentLocation={setCurrentLocation}
      />
    </Layout>
  )
}
