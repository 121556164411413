import styled, { css } from 'styled-components'
import React, { SetStateAction, useEffect, useState } from 'react'
import { DonationState, useDonationState } from '../../context/DonationContext'

const Layout = styled.div`
  padding: 0 20px;
  max-width: 768px;
  margin: 0 auto;
`

const SubjectWrapper = styled.div`
  padding: 20px 0 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SubjectText = styled.p`
  color: #1b1b1c;
  margin: 0;

  font-family: chosun;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;
`

const GoodsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: pretendard;
  justify-content: space-between;
`

const GoodsButtonWrapper = styled.button`
  background-color: inherit;
  height: 110px;
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: start;
  border: none;
`

const CheckIcon = styled.img<{ value: string; selectedvalue: string }>`
  opacity: 0.5;
  ${(props) =>
          props.selectedvalue === props.value &&
          css`
            opacity: 1;
          `}
`

const GoodsImage = styled.img`
  width: 66px;
  border-radius: 8px;
`

const GoodsInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-family: pretendard;
  flex-grow: 1;
`

const GoodsName = styled.p`
  align-self: start;
  color: ${({ theme }) => theme.color.grey90};
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
`

const GoodsDetailsWrapper = styled.div`
  color: ${({ theme }) => theme.color.grey60};
  margin: 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.26px;
`

const GoodsDetails = styled.div`
  height: 54px;
  margin: 0;
  text-align: start;
  overflow: hidden;
`

const GoodsButton = (
  d: { img: string; name: string; content: string },
  index: number,
  selectedValue: string,
  setSelectedValue: React.Dispatch<SetStateAction<string>>,
  donationState: DonationState
) => {
  const onClick = async () => {
    selectedValue !== d.name ? setSelectedValue(d.name) : setSelectedValue('')

    selectedValue !== d.name
      ? donationState.setDonation({ ...donationState.donation, goodsName: d.name })
      : donationState.setDonation({ ...donationState.donation, goodsName: '' })
  }

  return (
    <GoodsButtonWrapper key={index} onClick={onClick}>
      <CheckIcon src={`./img/check.svg`} value={d.name} selectedvalue={selectedValue} />
      <GoodsImage src={d.img} />
      <GoodsInfo>
        <GoodsName>{`${d.name}`}</GoodsName>
        <GoodsDetailsWrapper>
          <GoodsDetails>{`${d.content}`}</GoodsDetails>
        </GoodsDetailsWrapper>
      </GoodsInfo>
    </GoodsButtonWrapper>
  )
}

const GoodsCards = ({ filteredData }: { filteredData: { name: string; img: string; content: string }[] }) => {
  const [selectedValue, setSelectedValue] = useState('')

  useEffect(() => {
    setSelectedValue('')
  }, [filteredData])

  const donationState = useDonationState()

  const cards = filteredData.map((d, index) => {
    return GoodsButton(d, index, selectedValue, setSelectedValue, donationState)
  })

  return <>{cards}</>
}

export const ChoiceGoods = ({ filteredData }: { filteredData: { name: string; img: string; content: string }[] }) => {
  return filteredData.length !== 0 ? (
    <Layout>
      <GoodsListWrapper>
        <SubjectWrapper>
          <SubjectText>{`굿즈 선택하기`}</SubjectText>
        </SubjectWrapper>
        <GoodsCards filteredData={filteredData} />
      </GoodsListWrapper>
    </Layout>
  ) : (
    <></>
  )
}
