import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { GetFestivalDto } from '../../firebase/festival'
import { CarouselBox } from '../../components/Carousel'
import { mediaQuery } from '../../util/responsiveUtils'
import { useFestivals } from '../../context/FestivalContext'
import { useNavigate } from 'react-router-dom'

const Background = styled.div`
  background-color: ${({ theme }) => theme.color.almostWhite};
`

const Layout = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  padding: 30px 0 27px 0;
  align-items: center;
`

const DateButton = styled.button<{ active: 'true' | 'false' }>`
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid #dee2e6;
  border: ${(props) => props.active == 'true' ? `1px solid #dee2e6;` : `none;`};
  background-color: ${(props) => props.active == 'true' ? `white;` : `rgb(248, 248, 248);`};
`

const DateText = styled.p`
  color: var(--gray-90, #131415);
  font-family: chosun;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 122.222% */
  letter-spacing: -0.36px;
  margin: 0;
`

const FestivalWrapper = styled.button`
  width: 320px;

  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 1.59307px 4.14199px 0px rgba(0, 0, 0, 0.15);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  border: none;
  overflow: hidden;
  padding: 0;
  margin-bottom: 10px;
`

const FestivalImage = styled.img`
  display: flex;
  width: 320px;
  height: 200px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: #fff;
  object-fit: cover;
`

const FestivalTitleWrapper = styled.div`
  color: var(--gray-90, #212529);
  text-align: center;

  font-family: chosun;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  letter-spacing: -0.24px;
`

const FestivalTitle = styled.div`
  height: 72px;

  display: flex;
  padding: 16px 20px 4px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  align-self: stretch;
  background: var(--background-base, #fff);
`

const FestivalInfo = styled.div`
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid var(--gray-03, #f0f3f4);
  background: var(--background-base, #fff);
`

const FestivalInfoTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-family: chosun;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
`

const InfoTitle = styled.div`
  color: var(--text-assistant, #5e6062);
  font-family: chosun;
`

const InfoContent = styled.div`
  color: var(--text-emphasis, #1b1b1c);
  text-align: right;
`

const FestivalLayout = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0 auto;
  gap: 20px;
  padding-bottom: 20px;
`

const FestivalCard = ({ d }: { d: GetFestivalDto }) => {
  const navigator = useNavigate()

  const onClick = () => {
    navigator(`/event/${d.id}`, { state: { page: 'event', selectedIndex: 2 } })
  }

  return (
    <FestivalWrapper onClick={onClick}>
      <FestivalImage src={d.image.src} />
      <FestivalTitleWrapper>
        <FestivalTitle>{d.title}</FestivalTitle>
      </FestivalTitleWrapper>
      <FestivalInfo>
        <FestivalInfoTextWrapper>
          <InfoTitle>{`일정`}</InfoTitle>
          <InfoContent>{d.time}</InfoContent>
        </FestivalInfoTextWrapper>
        <FestivalInfoTextWrapper>
          <InfoTitle>{`장소`}</InfoTitle>
          <InfoContent>{d.place}</InfoContent>
        </FestivalInfoTextWrapper>
        <FestivalInfoTextWrapper>
          <InfoTitle>{`주최`}</InfoTitle>
          <InfoContent>{d.host}</InfoContent>
        </FestivalInfoTextWrapper>
      </FestivalInfo>
    </FestivalWrapper>
  )
}

const FGrid = styled.div<{ length: number }>`
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(1, minmax(1, 1fr));
  justify-content: space-between;
  place-content: stretch;

  ${mediaQuery('tablet')} {
    grid-template-columns: ${(props) =>
            props.length == 1 ? 'repeat(2, minmax(1, 2fr));' : 'repeat(2, minmax(0, 2fr));'}
  }
`

type monthType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export const Festival = () => {
  const festivals = useFestivals()
  const nowYear = new Date().getFullYear()
  const nowMonth = new Date().getMonth() + 1

  const [pickedFestivals, SetPickedFestivals] = useState(undefined as GetFestivalDto[] | undefined)
  const [sortedFestivals, SetSortedFestivals] = useState([] as GetFestivalDto[])
  const [selectedYear, SetSelectedYear] = useState(nowYear)
  const [selectedMonth, SetSelectedMonth] = useState(nowMonth as monthType)
  const [hasPrevData, SetHasPrevData] = useState(true)
  const [hasNextData, SetHasNextData] = useState(true)
  const [init, SetInit] = useState(false)

  useEffect(() => {
    if (festivals) {
      const sortedFestivals = festivals.sort((a, b) => {
        const aDate = new Date(a.date).valueOf()
        const bDate = new Date(b.date).valueOf()
        return aDate - bDate
      })
      SetSortedFestivals(sortedFestivals)
      SetInit(true)
    }
  }, [festivals])

  const getPickedFestivals = (year: number, month: monthType) => {
    return sortedFestivals.filter(f => {
      const fDate = new Date(f.date)
      return fDate.getFullYear() == year && fDate.getMonth() + 1 == month
    })
  }

  useEffect(() => {
    if (festivals && sortedFestivals && init) {
      const firstFestivalDate = new Date(sortedFestivals[0].date)
      const lastFestivalDate = new Date(sortedFestivals[sortedFestivals.length - 1].date)

      const hasPrevData = firstFestivalDate.getFullYear() < selectedYear ||
        (firstFestivalDate.getFullYear() == selectedYear && firstFestivalDate.getMonth() + 1 < selectedMonth)

      const hasNextData = lastFestivalDate.getFullYear() > selectedYear ||
        (lastFestivalDate.getFullYear() == selectedYear && lastFestivalDate.getMonth() + 1 > selectedMonth)

      SetHasPrevData(hasPrevData)
      SetHasNextData(hasNextData)

      SetPickedFestivals(getPickedFestivals(selectedYear, selectedMonth))
    }
  }, [selectedYear, selectedMonth, init])

  const onClickArrowButton = (number: number) => {
    if (number == -1) {
      if (hasPrevData) {
        if (selectedMonth == 1) {
          SetSelectedYear(selectedYear - 1)
          SetSelectedMonth(12 as monthType)
        } else {
          SetSelectedMonth(selectedMonth - 1 as monthType)
        }
      } else return
    } else if (hasNextData) {
      if (selectedMonth == 12) {
        SetSelectedYear(selectedYear + 1)
        SetSelectedMonth(1 as monthType)
      } else {
        SetSelectedMonth(selectedMonth + 1 as monthType)
      }
    } else return
  }

  const PrevButton = () => {
    return (
      <DateButton active={hasPrevData ? 'true' : 'false'} onClick={() => onClickArrowButton(-1)}>
        <img src={`./img/icon/left_arrow.svg`} />
      </DateButton>
    )
  }

  const NextButton = () => {
    return (
      <DateButton active={hasNextData ? 'true' : 'false'} onClick={() => onClickArrowButton(1)}>
        <img src={`./img/icon/right_arrow.svg`} />
      </DateButton>
    )
  }

  const Festivals = ({ f }: { f: GetFestivalDto }) => {
    return (<FestivalLayout>
      <FestivalCard d={f} />
    </FestivalLayout>)
  }

  return (
    <Background>
      <Layout>
        <DateWrapper>
          <PrevButton />
          <DateText>{`${selectedMonth}월`}</DateText>
          <NextButton />
        </DateWrapper>
        {pickedFestivals &&
          <FGrid length={pickedFestivals.length}>
            {pickedFestivals.map((f: GetFestivalDto, i: number) => {
              return <Festivals key={i} f={f} />
            })}
          </FGrid>}
        <CarouselBox />
      </Layout>
    </Background>
  )
}
