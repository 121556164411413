import React, { useEffect, useState } from 'react'
import { Section } from '../../components/Section'
import styled from 'styled-components'
import { Subject, SubjectWithBack } from '../../components/Subject'
import { BottomNavigation } from '../../components/BottomNavigation'
import { ArticleCards } from '../../components/ArticleCard'
import { useNavigate } from 'react-router-dom'
import { BackButton } from '../../components/Button'
import { signOut } from 'firebase/auth'
import { useAuthState } from '../../context/AuthContext'
import { auth } from '../../firebase/config'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: chosun;
`

const BackGround = styled.div`
  background-color: ${({ theme }) => theme.color.almostWhite};
  width: 100%;
`
const SubjectWrapper = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ContentLayout = styled.div`
  padding: 0 20px;
  max-width: 768px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

const TitleWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.almostWhite};
  display: flex;
  flex-direction: row;
  gap: 18px;
`

const EmailText = styled.p`
  color: #3c3c3c;
  width: 100%;
  text-align: start;
  margin: 15px 0;

  /* chosun/24 */
  font-family: chosun;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  letter-spacing: -0.24px;
`

const ArticleCardsWrapper = styled.div`
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-bottom: 80px;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.grey20};
  max-width: 768px;
`

const ButtonWrapper = styled.button`
  color: black;
  font-family: chosun;
  padding: 0;
  border: none;
  background-color: inherit;
`

export const MyPage = () => {
  const navigate = useNavigate()
  const authState = useAuthState()

  const [email, setEmail] = useState('')

  useEffect(() => {
    if (authState.state === 'loading') {
    } else if (authState.state === 'error') {
      navigate('/login', { replace: true })
    } else if (authState.state === 'loaded') {
      if (!authState.user) navigate('/login', { replace: true })
    }

    setEmail(auth.currentUser?.email || '')
  }, [auth.currentUser])

  const LogOutButton = () => {
    const onClick = () => {
      signOut(auth).then(() => {
        navigate('/', { replace: true })
      })
    }

    return <ButtonWrapper onClick={onClick}>{`logout`}</ButtonWrapper>
  }

  return (
    <Section bgcolor={`#F8F8F8`}>
      <Layout>
        <SubjectWrapper>
          <SubjectWithBack text={`마이페이지`} fontSize={`18px`}>
            <BackButton>
              <img src={`./img/back_icon.svg`} />
            </BackButton>
          </SubjectWithBack>
        </SubjectWrapper>
        <Divider />
        <BackGround>
          <ContentLayout>
            <TitleWrapper>
              <EmailText>{email}</EmailText>
              <LogOutButton />
            </TitleWrapper>
            <BottomNavigation />
          </ContentLayout>
          <Subject fontSize={`20px`} text={`내 소원지 작성 기록`} />
          <ContentLayout>
            <ArticleCardsWrapper>
              <ArticleCards />
            </ArticleCardsWrapper>
          </ContentLayout>
        </BackGround>
      </Layout>
    </Section>
  )
}
