import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyDVQSiduE63O02t33bzEvbilF9PdK083d4',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'mdbr-9ba9b.firebaseapp.com',
  databaseURL:
    process.env.REACT_APP_FIREBASE_DATABASE_URL ||
    'https://mdbr-9ba9b-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'mdbr-9ba9b',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'mdbr-9ba9b.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '583534284384',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '1:583534284384:web:ae3e2c1050d295ee90fd05',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-DNQJFKD5ZK',
}

export default firebaseConfig

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)

export { app, auth, db, storage }
