import styled from 'styled-components'
import { ReactNode } from 'react'

const SectionWrapper = styled.section<{ bgcolor: string }>`
  background-color: ${(props) => props.bgcolor};
  display: flex;
  flex-direction: column;
  position: relative;
  scroll-behavior: smooth;
  justify-content: start;
  //width: 100%;
  min-height: 100vh;
`

interface SectionProps {
  children: ReactNode
  bgcolor?: string
}

export const Section = ({ children, bgcolor = '#ffffff' }: SectionProps) => {
  return <SectionWrapper bgcolor={bgcolor}>{children}</SectionWrapper>
}
