import { GetFestivalDto, getFestivals } from '../firebase/festival'
import React, { createContext, useContext, useEffect, useState } from 'react'

const FestivalContext = createContext<GetFestivalDto[] | undefined>(undefined)

export const FestivalContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [festivals, setFestivals] = useState([{} as GetFestivalDto])

  useEffect(() => {
    getFestivals().then((r) => {
      Array.isArray(r) && setFestivals(r)
    })
  }, [])

  return <FestivalContext.Provider value={festivals}>{children}</FestivalContext.Provider>
}

export const useFestivals = () => {
  const festivals = useContext(FestivalContext)
  if (!festivals) throw new Error('FestivalProvider not found')
  return festivals
}
