import { User } from 'firebase/auth';
import firebase from 'firebase/compat';
import Timestamp = firebase.firestore.Timestamp;

type ErrorDto = {
  code?: string | number;
  msg?: string;
};

type ResponseDto = {
  body?: any | User;
  error?: ErrorDto;
};

export interface UpdateField {
  updatedby?: string;
  lastupdate?: Timestamp;
}

export interface CreateField extends UpdateField {
  createdby?: string;
  createdate?: Timestamp;
}

const mapError = (Error: any) => {
  const errorDto = { code: Error.code, msg: Error.message };
  console.log(Object.values(errorDto).join(', '));
  return { error: errorDto };
};

const writeError = (msg: string) => console.log(msg);

const returnFalse = (Error: any): boolean => {
  writeError(Error);
  return false;
};

const returnUndefined = (Error: any): undefined => {
  writeError(Error);
  return undefined;
};

const getCurrentTimestamp = (): Timestamp => Timestamp.now();

const birthToDate = (birth: string): string => {
  const firstChar = birth.slice(0, 1);
  const yy = firstChar !== '0' && firstChar !== '1' ? '19' : '20';
  const date = `${yy}${birth.slice(0, 2)}-${birth.slice(2, 4)}-${birth.slice(4)}`;
  return date;
};

const timestampToDate = (timestamp?: Timestamp) => {
  if (!timestamp) return '';
  const jsDate = timestamp.toDate();
  const yyyy = jsDate.getFullYear();
  const mm = jsDate.getMonth() + 1;
  const dd = jsDate.getDate();
  return `${yyyy}.${mm.toString().padStart(2, '0')}.${dd.toString().padStart(2, '0')}`;
};

export type { ErrorDto, ResponseDto };
export { mapError, getCurrentTimestamp, returnFalse, returnUndefined, birthToDate, timestampToDate };
