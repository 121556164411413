import { generateAuthCode } from '../util/utils'
import { db } from './config'
import { mapError, ResponseDto } from './common'
import emailjs from '@emailjs/browser'
import dotenv from 'dotenv'
import { addDoc, collection } from 'firebase/firestore'

export const sendMail = async (email: string) => {
  dotenv.config()

  const authCode = generateAuthCode(6)

  const params = {
    serviceID: process.env.REACT_APP_EMAILJS_SERVICE_ID || 'service_4h0byat',
    templateID: process.env.REACT_APP_EMAILJS_TEMPLETE_ID || 'template_hplxibn',
    templateParams: {
      email: email,
      subject: `[MWBR] 이메일 코드를 확인해주세요.`,
      message: `인증코드는 ${authCode} 입니다.`,
      from: process.env.REACT_APP_GMAIL_ADDRESS || 'jangseung2023@gmail.com',
    },
    publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY || '1pDEDW4mVJcZ4NFrK',
  }

  // await push(ref(db, 'auth/'), {
  //   email: email,
  //   code: authCode,
  // })
  //   .then(() => {
  //     emailjs
  //       .send(params.serviceID, params.templateID, params.templateParams, params.publicKey)
  //       .then((result) => {
  //         return { body: result } as ResponseDto
  //       })
  //       .catch((error) => mapError(error))
  //   })
  //   .catch((error) => mapError(error))

  await addDoc(collection(db, 'auth'), { email, code: authCode })
    .then((snapshot) => {
      !!snapshot &&
        emailjs
          .send(params.serviceID, params.templateID, params.templateParams, params.publicKey)
          .then((result) => {
            return { body: result } as ResponseDto
          })
          .catch((error) => mapError(error))
    })
    .catch((error) => mapError(error))
}
