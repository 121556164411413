import { db } from './config'
import { createAuth } from './auth'
import { birthToDate, getCurrentTimestamp, mapError, ResponseDto, returnFalse } from './common'
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore'

type GetUserReq = {
  email: string
}

type UserDto = {
  email: string
}

const existUser = async ({ email }: GetUserReq) => {
  const q = query(collection(db, 'user'), where('email', '==', email))
  return await getDocs(q).then((snapshot) => {
    return !snapshot.empty
  })
}

type SignUpReq = {
  email: string
  password: string
  name: string
  birth: string
}

const signUp = async ({ email, password, name, birth }: SignUpReq) => {
  return await createAuth({ email, password })
    .then(async (res: ResponseDto) => {
      const uid = res.body
      return await createUser({ email, name, birth, uid })
        .then(() => true)
        .catch(mapError)
    })
    .catch(mapError)
}

type CreateUserReq = Omit<SignUpReq, 'password'> & { uid: string }

const createUser = async ({ email, name, birth, uid }: CreateUserReq) => {
  const now = getCurrentTimestamp()
  const birthDate = birthToDate(birth)
  return await setDoc(doc(db, 'users', uid), {
    email,
    name,
    birth: birthDate,
    createdate: now,
    updatedate: now,
  })
    .then(() => {
      return true
    })
    .catch(returnFalse)
}

export { existUser, signUp }
