import styled from 'styled-components'
import { useFestivals } from '../../context/FestivalContext'
import { GetFestivalDto } from '../../firebase/festival'
import { mediaQuery } from '../../util/responsiveUtils'
import { useNavigate } from 'react-router-dom'

const Background = styled.div`
  background-color: ${({ theme }) => theme.color.almostWhite};
`

const Layout = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const FestivalCardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

const SimpleFestivalCardWrapper = styled.button`
  border: none;
  outline: none;
  padding: 0;
  background-color: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const FestivalImage = styled.img`
  width: 100%;
  height: 100px;
  display: flex;
  object-fit: cover;
  min-width: 320px;
  max-width: 640px;
  min-height: 100px;
  max-height: 200px;
  border-radius: 4px;

  ${mediaQuery('tablet')} {
    height: 200px;
  }
`

const FestivalTitle = styled.div`
  color: #1B1B1C;
  text-align: center;

  font-family: chosun;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  letter-spacing: -0.24px;
`

const FestivalDate = styled.div`
  color: #1B1B1C;
  text-align: center;

  /* chosun/16 */
  font-family: chosun;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: -0.32px;
`

const SimpleFestivalCard = ({ festival }: { festival: GetFestivalDto }) => {
  const navigator = useNavigate()

  const onClick = () => {
    navigator(`/event/${festival.id}`, { state: { page: 'event', selectedIndex: 2 } })
  }

  return (
    <SimpleFestivalCardWrapper onClick={onClick}>
      <FestivalImage src={festival.image.src} />
      <FestivalTitle>{festival.title}</FestivalTitle>
      <FestivalDate>{festival.date}</FestivalDate>
    </SimpleFestivalCardWrapper>
  )
}

export const News = () => {

  const festivals = useFestivals()
  const cards = festivals.sort((a, b) => {
    const aDate = new Date(a.date).valueOf()
    const bDate = new Date(b.date).valueOf()
    return bDate - aDate
  }).map((f, i) => {
    return <SimpleFestivalCard key={i} festival={f} />
  })

  return (
    <Background>
      <Layout>
        <FestivalCardsWrapper>
          {cards}
        </FestivalCardsWrapper>
      </Layout>
    </Background>
  )
}
