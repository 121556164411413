import styled from 'styled-components'
import { mediaQuery } from '../util/responsiveUtils'
import { ReactNode } from 'react'

interface SubjectTextProps {
  text?: string
  fontSize?: string
  align?: string
}

export const Subject = ({ text, fontSize = `24px;`, align = 'start;' }: SubjectTextProps) => {
  const SubjectWrapper = styled.div<SubjectTextProps>`
    width: 100%;
    text-align: ${(props) => props.align};
  `

  const SubjectText = styled.div<{ fontSize: string }>`
    padding: 15px 20px;
    color: #3c3c3c;
    font-family: chosun;
    font-size: ${(props) => props.fontSize};
    margin: 0 auto;

    ${mediaQuery('tablet')} {
      max-width: 768px;
    }
  `

  return (
    <SubjectWrapper fontSize={fontSize} align={align}>
      <SubjectText fontSize={fontSize}>{text}</SubjectText>
    </SubjectWrapper>
  )
}

interface SubjectWithBackProps {
  children?: ReactNode
  text?: string
  fontSize?: string
}

export const SubjectWithBack = ({ children, text, fontSize = `18px` }: SubjectWithBackProps) => {
  const SubjectWitBackWrapper = styled.div<SubjectTextProps>`
    width: 100%;
    max-width: 768px;
    background-color: white;
  `

  const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 14px 16px;
    align-items: center;
  `

  const SubjectWithBackText = styled.div<{ fontSize: string }>`
    color: #1b1b1c;
    font-family: chosun;
    font-size: ${(props) => props.fontSize};
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 111.111% */
  `

  return (
    <SubjectWitBackWrapper fontSize={fontSize}>
      <Wrapper>
        {children}
        <SubjectWithBackText fontSize={fontSize}>{text}</SubjectWithBackText>
      </Wrapper>
    </SubjectWitBackWrapper>
  )
}
