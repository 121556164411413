import styled from 'styled-components';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mediaQuery } from '../util/responsiveUtils';

const Layout = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 16px 16px 0 0;
  background: var(--background-base, #fff);
  box-shadow: 0px -1px 10px 0px rgba(20, 70, 50, 0.08);
  margin: 0 auto;

  ${mediaQuery('tablet')} {
    max-width: 768px;
  }
}
`;
const BottomNavWrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: stretch;
  gap: 10px;
`;

const NavigationText = styled.p`
  margin: 2px 0 0 0;
  font-family: pretendard;
  font-size: 10px;
  font-style: normal;
  white-space: nowrap;
  color: #000;
`;

const NavigationButton = styled.button`
  flex-basis: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: none;
  background-color: white;
`;

const IconWrapper = styled.div<{ state: string; value: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  opacity: ${(props) => (props.state === props.value ? '1;' : '0.5;')};
`;

interface BottomNavigationProps {
  nameEng: string;
}

export const BottomNavigation = () => {
  const names = {
    home: '홈',
    article: '소원지 쓰기',
    event: '이벤트',
    location: '소원지 장소',
    mypage: '마이페이지',
  };

  const navigate = useNavigate();

  const Icon = ({ nameEng }: BottomNavigationProps) => {
    const nameKor = Object.values(names)[Object.keys(names).indexOf(nameEng)];
    const { state } = useLocation();
    return (
      <IconWrapper state={state?.page || 'home'} value={nameEng || 'home'}>
        <img src={`/img/icon/${nameEng}.svg`} width={24} />
        <NavigationText>{nameKor}</NavigationText>
      </IconWrapper>
    );
  };

  return (
    <Layout>
      <BottomNavWrapper>
        <NavigationButton value={'home'} onClick={(e) => navigate(`/`, { state: { page: e.currentTarget.value } })}>
          <Icon nameEng={'home'} />
        </NavigationButton>
        <NavigationButton
          value={'article'}
          onClick={(e) => navigate(`/article`, { state: { page: e.currentTarget.value } })}
        >
          <Icon nameEng={'article'} />
        </NavigationButton>
        <NavigationButton
          value={'event'}
          onClick={(e) => navigate(`/event`, { state: { page: e.currentTarget.value } })}
        >
          <Icon nameEng={'event'} />
        </NavigationButton>
        <NavigationButton
          value={'location'}
          onClick={(e) => navigate(`/location`, { state: { page: e.currentTarget.value } })}
        >
          <Icon nameEng={'location'} />
        </NavigationButton>
        <NavigationButton
          value={'mypage'}
          onClick={(e) => navigate(`/mypage`, { state: { page: e.currentTarget.value } })}
        >
          <Icon nameEng={'mypage'} />
        </NavigationButton>
      </BottomNavWrapper>
    </Layout>
  );
};
